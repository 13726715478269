// stylelint-disable property-blacklist, scss/dollar-variable-default

// SCSS RFS mixin
//
// Tailles de polices responsives automatisées

// Configuration

// Base font size
$rfs-base-font-size: 0.781rem !default;
$rfs-font-size-unit: rem !default;

@if $rfs-font-size-unit != rem and $rfs-font-size-unit != px {
  @error "`#{$rfs-font-size-unit}` n'est pas une unité valide pour $rfs-font-size-unit. Utiliser `px` ou `rem`.";
}

// Breakpoint à l'endroit où la taille de la police commence à diminuer si la largeur de l'écran est plus petite
$rfs-breakpoint: 1200px !default;
$rfs-breakpoint-unit: px !default;

@if $rfs-breakpoint-unit != px and $rfs-breakpoint-unit != em and $rfs-breakpoint-unit != rem {
  @error "`#{$rfs-breakpoint-unit}` n'est pas une unité valide pour $rfs-breakpoint-unit. Utiliser `px`, `em` ou `rem`.";
}

// Redimensionner la taille de la police en fonction de la hauteur et de la largeur de l'écran
$rfs-two-dimensional: false !default;

// Facteur de diminution
$rfs-factor: 10 !default;

@if type-of($rfs-factor) != "number" or $rfs-factor <= 1 {
  @error "`#{$rfs-factor}` n'est pas valide  $rfs-factor, il doit être supérieur à 1.";
}

// Générer activer ou désactiver des classes. Possibilités: false, "enable" ou "disable"
$rfs-class: false !default;

// 1 rem = $rfs-rem-value px
$rfs-rem-value: 10 !default;

// Safari iframe resize bug: https://github.com/twbs/rfs/issues/14
$rfs-safari-iframe-resize-bug-fix: false !default;

// Désactivez RFS en définissant $enable-responsive-font-sizes à false
$enable-responsive-font-sizes: true !default;

// Unité de cache $rfs-base-font-size
$rfs-base-font-size-unit: unit($rfs-base-font-size);

// Supprimer l'unité px de $rfs-base-font-size pour les calculs
@if $rfs-base-font-size-unit == "px" {
  $rfs-base-font-size: $rfs-base-font-size / ($rfs-base-font-size * 0 + 1);
}
@else if $rfs-base-font-size-unit == "rem" {
  $rfs-base-font-size: $rfs-base-font-size / ($rfs-base-font-size * 0 + 1 / $rfs-rem-value);
}

// Unité de cache $rfs-breakpoint pour éviter les appels multiples
$rfs-breakpoint-unit-cache: unit($rfs-breakpoint);

// Supprimer l'unité de from $rfs-breakpoint pour les calculs
@if $rfs-breakpoint-unit-cache == "px" {
  $rfs-breakpoint: $rfs-breakpoint / ($rfs-breakpoint * 0 + 1);
}
@else if $rfs-breakpoint-unit-cache == "rem" or $rfs-breakpoint-unit-cache == "em" {
  $rfs-breakpoint: $rfs-breakpoint / ($rfs-breakpoint * 0 + 1 / $rfs-rem-value);
}

// Mixin interne qui ajoute des classes de désactivation au sélecteur si nécessaire.
@mixin _rfs-disable-class {
  @if $rfs-class == "disable" {
    // L'ajout d'une classe supplémentaire augmente la spécificité, ce qui empêche la requête multimédia de remplacer la taille de la police
    &,
    .disable-responsive-font-size &,
    &.disable-responsive-font-size {
      @content;
    }
  }
  @else {
    @content;
  }
}

// Mixin interne qui ajoute des classes d'activation au sélecteur si nécessaire.
@mixin _rfs-enable-class {
  @if $rfs-class == "enable" {
    .enable-responsive-font-size &,
    &.enable-responsive-font-size {
      @content;
    }
  }
  @else {
    @content;
  }
}

// Mixin interne utilisé pour déterminer quelle requête multimédia doit être utilisée
@mixin _rfs-media-query($mq-value) {
  @if $rfs-two-dimensional {
    @media (max-width: #{$mq-value}), (max-height: #{$mq-value}) {
      @content;
    }
  }
  @else {
    @media (max-width: #{$mq-value}) {
      @content;
    }
  }
}

// Mixin font size responsive
@mixin rfs($fs, $important: false) {
  // Cache $fs
  $fs-unit: if(type-of($fs) == "number", unit($fs), false);

  // Ajoute !important suffix si besoin
  $rfs-suffix: if($important, " !important", "");

  // Si $fs n'est pas un nombre (comme inherit) ou $fs a une unité (pas px ou rem, comme 1.5em) ou $fs vaut 0, affichez simplement la valeur
  @if not $fs-unit or $fs-unit != "" and $fs-unit != "px" and $fs-unit != "rem" or $fs == 0 {
    font-size: #{$fs}#{$rfs-suffix};
  }
  @else {
    // Supprimer l'unité de $fs pour les calculs
    @if $fs-unit == "px" {
      $fs: $fs / ($fs * 0 + 1);
    }
    @else if $fs-unit == "rem" {
      $fs: $fs / ($fs * 0 + 1 / $rfs-rem-value);
    }

    // Définir la taille de police par défaut
    $rfs-static: if($rfs-font-size-unit == rem, #{$fs / $rfs-rem-value}rem, #{$fs}px);

    // Ajoutez la média querie uniquement si la taille de la police est supérieure à la taille de police minimale.
    @if $fs <= $rfs-base-font-size or not $enable-responsive-font-sizes {
      font-size: #{$rfs-static}#{$rfs-suffix};
    }
    @else {
      // Calculez la taille de police minimale pour $fs
      $fs-min: $rfs-base-font-size + ($fs - $rfs-base-font-size) / $rfs-factor;

      // Calculer la différence entre $fs et la taille de police minimale
      $fs-diff: $fs - $fs-min;

      // Format de base de la taille de la police
      $min-width: if($rfs-font-size-unit == rem, #{$fs-min / $rfs-rem-value}rem, #{$fs-min}px);

      // Utilisez `vmin` si le mode bidimensionnel est activé
      $variable-unit: if($rfs-two-dimensional, vmin, vw);

      // Calculer la largeur variable entre 0 et $rfs-breakpoint
      $variable-width: #{$fs-diff * 100 / $rfs-breakpoint}#{$variable-unit};

      // Définir la taille de police calculée
      $rfs-fluid: calc(#{$min-width} + #{$variable-width}) #{$rfs-suffix};

      // Formatte le breakpoint
      $mq-value: if($rfs-breakpoint-unit == px, #{$rfs-breakpoint}px, #{$rfs-breakpoint / $rfs-rem-value}#{$rfs-breakpoint-unit});

      @include _rfs-disable-class {
        font-size: #{$rfs-static}#{$rfs-suffix};
      }

      @include _rfs-media-query($mq-value) {
        @include _rfs-enable-class {
          font-size: $rfs-fluid;
        }

        // Inclure le correctif de redimensionnement safari iframe si nécessaire
        min-width: if($rfs-safari-iframe-resize-bug-fix, (0 * 1vw), null);
      }
    }
  }
}

// Les mixins taille de police & responsive-font-size utilisent RFS pour redimensionner la taille de la police
@mixin font-size($fs, $important: false) {
  @include rfs($fs, $important);
}

@mixin responsive-font-size($fs, $important: false) {
  @include rfs($fs, $important);
}
