:root {
  // Les valeurs de variables personnalisées ne prennent en charge que SassScript `#{}`.
  @each $color, $value in $colors {
    --#{$color}: #{$value};
  }

  @each $color, $value in $theme-colors {
    --#{$color}: #{$value};
  }
}
