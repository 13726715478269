/* stylelint-disable selector-max-id */
.mcf-template--epi {
  overflow: inherit !important; // Nécessaire au sticky

  .mcf-template__header {
    box-shadow: $epi-header-box-shadow;
    -ms-overflow-style: none;
  }

  .mcf-wrapper {
    display: flex;
    padding-left: 0;
    background-color: $epi-sidebar-background-color;

    /*@supports (position: sticky) {
      background-color: inherit;
    }*/
    @include transition($epi-sidebar-transition);

    nav {
      &.mcf-sidebar-wrapper {
        .mcf-nav--light {
          .mcf-nav__link {
            font-size: 1.125rem;
            color: $epi-nav-link-color;
            @include padding-left(10);

            @include hover-focus {
              color: $epi-nav-link-color-focus;
            }

            &.active {
              font-weight: 600;
              color: $epi-nav-link-color-selected;
            }
          }
        }
      }
    }

    &.toggled {
      & .mcf-sidebar-wrapper {
        width: 100%;

        @include media-breakpoint-up(md) {
          width: $epi-sidebar-width-lg;
        }
      }

      & .mcf-page-content-wrapper {
        width: 0;
        overflow: hidden;

        @include media-breakpoint-up(md) {
          width: 100%;
        }
      }
    }
  }

  .mcf-sidebar-wrapper {
    @include padding-y(4);
    @include transition($epi-sidebar-transition);
    //position: sticky;
    top: $epi-sidebar-top;
    left: $epi-sidebar-left;
    width: 0;
    height: 100vh;
    overflow-y: hidden;
    background-color: $epi-sidebar-background-color;
  }

  .mcf-page-content-wrapper {
    width: 100%;
  }

  @include media-breakpoint-only(lg) {
    .mcf-wrapper {
      padding-left: $epi-sidebar-width-sm;

      &.mcf-wrapper--fluid {
        width: 100%;
        padding-left: 0;
      }

      & .mcf-sidebar-wrapper {
        height: 100%;
        width: $epi-sidebar-width-sm;
      }

      & .mcf-page-content-wrapper {
        margin-right: -$epi-sidebar-width-sm;
      }
    }

    .mcf-sidebar-wrapper {
      left: $epi-sidebar-left-sm;
      margin-left: -$epi-sidebar-width-sm;
    }

    .mcf-footer {
      padding-right: $epi-sidebar-width-sm;
    }
  }

  &.mcf-disable-wrapper{
    #mcf-menu-toggle{
      display: none !important;
    }
    .mcf-wrapper{
      padding: 0;
      & .mcf-page-content-wrapper {
        margin-right: 0;
      }
    }
  }

  @include media-breakpoint-up(xl) {
    .mcf-wrapper {
      padding-left: $epi-sidebar-width-lg;

      &.mcf-wrapper--fluid {
        width: 100%;
        padding-left: 0;
      }

      & .mcf-sidebar-wrapper {
        height: 100%;
        width: $epi-sidebar-width-lg;
      }

      & .mcf-page-content-wrapper {
        margin-right: -$epi-sidebar-width-lg;
      }
    }

    .mcf-sidebar-wrapper {
      left: $epi-sidebar-left-lg;
      margin-left: -$epi-sidebar-width-lg;
    }

    .mcf-footer {
      padding-right: $epi-sidebar-width-lg;
    }
  }

  .mcf-footer {
    position: absolute;
    bottom: 0;
  }

  .mcf-margin-footer {
    margin-bottom: $epi-margin-footer;
  }
}
