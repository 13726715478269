// .mcf-modal--open      - body class pour enlever le scroll
// .mcf-modal            - container pour scroll dans la modal
// .mcf-modal__dialog    - positionnement pour la modal actuel
// .mcf-modal__content   - style border largeur bg et autres


// Enleve le scroll sur le body
.mcf-modal--open {
  overflow: hidden;
}

// Conteneur dans lequel le modal défile
.mcf-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal;
  display: none;
  overflow: hidden;
  // Empêche Chrome sur Windows d'ajouter un contour de mise au point. Pour plus de détails, voir
  // https://github.com/twbs/bootstrap/pull/10951.
  outline: 0;
  // Nous n'utilisons pas délibérément `-webkit-overflow-scrolling: touch;` en
  // raison d'un bug iOS Safari: https://bugs.webkit.org/show_bug.cgi?id=158342

  .mcf-modal--open & {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// Div pour positionner la modal avec padding bottom
.mcf-modal__dialog {
  position: relative;
  width: auto;
  margin: $modal-dialog-margin;
  // Autorise les clics à passer pour la gestion des clics personnalisés pour fermer le modal
  pointer-events: none;

  // Lorsque vous perdez la modal, elle s'anime pour qu'elle glisse vers le bas
  .mcf-modal.fade & {
    @include transition($modal-transition);
    transform: translate(0, -25%);
  }
  .mcf-modal.show & {
    transform: translate(0, 0);
  }
}

.mcf-modal__dialog--centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - (#{$modal-dialog-margin} * 2));
}

// Actual modal
.mcf-modal__content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%; // Pour s'assurer que `.mcf-modal__content` s'étend sur la largeur totale de son parent `.mcf-modal__dialog`
  // contrecarrer les événements de pointer-events: none; dans la .mcf-modal__dialog
  pointer-events: auto;
  background-color: $modal-content-bg;
  background-clip: padding-box;
  border: $modal-content-border-width solid $modal-content-border-color;
  @include border-radius($modal-content-border-radius);
  @include box-shadow($modal-content-box-shadow-xs);
  // Supprimer le contour du focus de la modal ouverte
  outline: 0;
}

// Modal background
.mcf-modal__backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-modal-backdrop;
  background-color: $modal-backdrop-bg;

  // Fade pour backdrop
  &.fade { opacity: 0; }
  &.show { opacity: $modal-backdrop-opacity; }
}

// Modal header
// Top section du modal w/ titre et bouton close
.mcf-modal__header {
  display: flex;
  align-items: flex-start; // donc le btn proche reste toujours dans le coin supérieur droit
  justify-content: space-between; // Mettez les éléments d'en-tête modaux (titre et bouton close) aux extrémités opposées
  padding: $modal-header-padding;
  border-bottom: $modal-header-border-width solid $modal-header-border-color;
  @include border-top-radius($modal-content-border-radius);

  .close {
    padding: $modal-header-padding;
    // auto sur l'icône de force de gauche vers la droite même s'il n'y a pas de .mcf-modal__title
    margin: (-$modal-header-padding) (-$modal-header-padding) (-$modal-header-padding) auto;
  }
}

// Titre du texte dans l'en-tête
.mcf-modal__title {
  margin-bottom: 0;
  line-height: $modal-title-line-height;
}

// Modal body
// Où réside tout le contenu modal (frère de .mcf-modal__header et .modal__footer)
.mcf-modal__body {
  position: relative;
  // Activer `flex-grow: 1` de sorte que le body occupe autant d'espace que possible
  // quand il devrait y avoir une hauteur fixe sur `.mcf-modal__dialog`.
  flex: 1 1 auto;
  padding: $modal-inner-padding;
}

// Footer (pour les actions)
.mcf-modal__footer {
  display: flex;
  align-items: center; // alignement vertical
  justify-content: flex-end; // Aligner à droite les boutons avec la propriété flex car text-align ne fonctionne pas sur les éléments flex
  padding: $modal-inner-padding;
  border-top: $modal-footer-border-width solid $modal-footer-border-color;

  // Placez facilement la marge entre les éléments de pied de page
  > :not(:first-child) { margin-left: 0.156rem; }
  > :not(:last-child) { margin-right: 0.156rem; }
}

// Mesurer la largeur de la barre de défilement pour le body pendant l'affichage/masquage de la modal
.mcf-modal__scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

// Augmenter la modal
@include media-breakpoint-up(sm) {
  // Définit automatiquement la largeur du modal pour les plus grandes fenêtres
  .mcf-modal__dialog {
    max-width: $modal-md;
    margin: $modal-dialog-margin-y-sm-up auto;
  }

  .mcf-modal__dialog--centered {
    min-height: calc(100% - (#{$modal-dialog-margin-y-sm-up} * 2));
  }

  .mcf-modal__content {
    @include box-shadow($modal-content-box-shadow-sm-up);
  }

  .mcf-modal--sm { max-width: $modal-sm; }

}

@include media-breakpoint-up(lg) {
  .mcf-modal--lg { max-width: $modal-lg; }
}
