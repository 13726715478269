// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s or `<ul>`s.

.mcf-nav {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  &--vertical {
    flex-direction: column;

    .mcf-nav__link {
      padding: $nav-link-padding-x $nav-link-padding-y;
    }
  }
}

.mcf-nav--scroll {
  min-width: max-content;
}

.mcf-nav--scroll {
  min-width: max-content;
}

.mcf-nav__link {
  display: block;
  padding: $nav-link-padding-y $nav-link-padding-x;

  .icon {
    margin-right: $nav-link-icon-margin-right;
  }

  @include hover-focus() {
    text-decoration: none;
  }

  // Disabled state lightens text
  &.disabled {
    color: $nav-link-disabled-color;
  }
}

.mcf-nav--dark {
  .mcf-nav__link {
    color: darken($nav-link-color, 30%);

    @include hover-focus() {
      color: darken($nav-link-color, 15%);
    }

    &.active {
      color: $nav-link-color;
    }
  }
}

.mcf-nav--light {
  .mcf-nav__link {
    color: rgba($nav-link-color, .4);

    @include hover-focus() {
      color: $nav-link-color;
    }

    &.active {
      color: $nav-link-color;
    }
  }
}

//
// Tabs
//

.mcf-nav__tabs {
  border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;

  .mcf-nav__item {
    margin-bottom: -$nav-tabs-border-width;
    line-height: $nav-light-item-line-height;
  }

  .mcf-nav__link {
    border: $nav-tabs-border-width solid transparent;
    @include border-top-radius($nav-tabs-border-radius);

    @include hover-focus() {
      border-color: $nav-tabs-link-hover-border-color;
    }

    &.disabled {
      color: $nav-link-disabled-color;
      background-color: transparent;
      border-color: transparent;
    }
  }

  .mcf-nav__link.active,
  .mcf-nav__item.show .mcf-nav__link {
    color: $nav-tabs-link-active-color;
    background-color: $nav-tabs-link-active-bg;
    border-color: $nav-tabs-link-active-border-color;
  }

  .mcf-dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: -$nav-tabs-border-width;
    // Remove the top rounded corners here since there is a hard edge above the menu
    @include border-top-radius(0);
  }
}

//
// Pills
//

.mcf-nav--pills {
  .mcf-nav__link {
    @include border-radius($nav-pills-border-radius);
  }

  .mcf-nav__link.active,
  .show > .mcf-nav__link {
    color: $nav-pills-link-active-color;
    background-color: $nav-pills-link-active-bg;
  }
}

//
// Justified variants
//

.mcf-nav--fill {
  .mcf-nav__item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.mcf-nav--justified {
  .mcf-nav__item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}

// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.mcf-tab__content {
  > .mcf-tab__pane {
    display: none;
  }

  > .active {
    display: block;
  }
}

.mcf-nav--left {
  top: 0;
  z-index: 1;
  width: 18.75rem;
  min-width: 18.75rem;

  @include media-breakpoint-only(lg) {
    top: 0;
    width: 12.5rem;
    min-width: 12.5rem;
  }

  @include media-breakpoint-down(md) {
    position: fixed;
    height: 100%;
  }
}

.mcf-nav__menu--left {
  top: 6.25rem;
  z-index: 1;
  width: 18.75rem;
  min-width: 18.75rem;

  @include media-breakpoint-only(lg) {
    top: 6.25rem;
    width: 12.5rem;
    min-width: 12.5rem;
  }
}

.mcf-nav__menu--footer {
  bottom: $nav-menu-footer-bottom;
}
