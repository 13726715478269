//
// Base styles
//
.mcf-loader {
  display: flex;
  min-width: $loader-size;
  max-width: $loader-size;
  min-height: $loader-size;
  max-height: $loader-size;
  border: $loader-border-size solid;
  border-radius: 50%;
  transform: rotate(45deg);

  &__ball {
    position: relative;
    min-width: $loader-ball-size;
    max-width: $loader-ball-size;
    min-height: $loader-ball-size;
    max-height: $loader-ball-size;
    margin: .3em auto;
    border-radius: 50%;
  }

  &--sm {
    min-width: $loader-size-sm;
    max-width: $loader-size-sm;
    min-height: $loader-size-sm;
    max-height: $loader-size-sm;
    border: $loader-border-size-sm solid;
    .mcf-loader__ball {
      min-width: $loader-ball-size-sm;
      max-width: $loader-ball-size-sm;
      min-height: $loader-ball-size-sm;
      max-height: $loader-ball-size-sm;
      margin: .15em auto;
    }
  }

  &--lg {
    min-width: $loader-size-lg;
    max-width: $loader-size-lg;
    min-height: $loader-size-lg;
    max-height: $loader-size-lg;
    border: $loader-border-size-lg solid;
    .mcf-loader__ball {
      min-width: $loader-ball-size-lg;
      max-width: $loader-ball-size-lg;
      min-height: $loader-ball-size-lg;
      max-height: $loader-ball-size-lg;
      margin: .45em auto;
    }
  }

  @each $color, $value in $colors {
    &--#{$color} {
      @include loader-variant($value, $color);
    }
  }

  @keyframes rotateLoader {
    from {
      transform: rotate(45deg);
    }
    to {
      transform: rotate(405deg);
    }
  }
}

.mcf-loader-chat{
  position: relative;
  height: $loader-chat-height;
  padding: $loader-chat-padding;
  font-size: .5em;
  line-height: $loader-chat-line-height;
  background-color: $loader-chat-bg;
  border-radius: $loader-chat-border-radius;

  & > span {
    display: inline-block;
    width: $loader-chat-ball-height;
    height: $loader-chat-ball-height;
    margin: $loader-chat-ball-margin;
    background-color: $loader-chat-ball-off-bg;
    border-radius: 100%;

    &:nth-child(1){
      animation: loaderball-bg-change 900ms ease-in-out infinite;
    }

    &:nth-child(2){
      animation: loaderball-bg-change 900ms ease-in-out 300ms infinite;
    }

    &:nth-child(3){
      animation: loaderball-bg-change 900ms ease-in-out 600ms infinite;
    }
  }

  @keyframes loaderball-bg-change{
    0%{
      background-color: $loader-chat-ball-on-bg;
    }

    33%,
    100%{
      background-color: $loader-chat-ball-off-bg;
    }
  }
}
