// Tables

@mixin table-row-variant($state, $background) {
  // Les sélecteurs exacts ci-dessous sont nécessaires pour remplacer
  // `.mcf-table--striped` et empêcher l’héritage des tables imbriquées.
  .mcf-table-#{$state} {
    &,
    > th,
    > td {
      background-color: $background;
    }
  }

  // Hover états pour `.mcf-table--hover`
  // Remarque: cette option n'est pas disponible pour les cellules ou les lignes comprises dans `thead` ou` tfoot`.
  .mcf-table--hover {
    $hover-background: darken($background, 5%);

    .mcf-table--#{$state} {
      @include hover {
        background-color: $hover-background;

        > td,
        > th {
          background-color: $hover-background;
        }
      }
    }
  }
}
