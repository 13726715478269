@each $color, $value in $colors {
    @include tunnel-mixin-color(".mcf-template--#{$color}", $value);
}

@each $color, $value in $colors {
    @include tunnel-mixin-mobile-color(".mcf-template-mobile--#{$color}", $value);
}

.mcf-template--background-image{
    z-index: -1;
    position: fixed;
    top: 0;
    left: 0;
    min-height: 100%;
    min-width: 100%;
}

@each $color, $value in $colors {
    .mcf-template-overlay--#{$color} {
        background-color: transparentize($value, .6);
    }
}