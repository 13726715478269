// Form control focus state
//
// Génére un état de focus personnalisé et pour toute entrée avec la couleur spécifiée,
// par défaut à la variable `$ input-focus-border-color`.
@mixin form-control-focus() {
  &:focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
  }
}

@mixin input-color-effects(
  $color,
  $focus-color: darken($color, 10%),
  $active-color: darken($color, 20%)
) {
  color: $color;
  &:focus,
  &:hover {
    color: $focus-color;
  }
  &:active {
    color: $active-color;
  }
}

@mixin form-control-hover() {
  &:hover {
    color: $input-hover-color;
    background-color: $input-hover-bg;
    border-color: $input-hover-border-color;
    outline: 0;
  }
}

@mixin form-validation-state-selector($state) {
  @if ($state == 'valid' or $state == 'invalid') {
    .was-validated &:#{$state},
    &.is-#{$state} {
      @content;
    }
  } @else {
    &.is-#{$state} {
      @content;
    }
  }
}

@mixin form-validation-state($state, $color, $icon) {
  .#{$state}-feedback {
    display: none;
    width: 100%;
    margin-top: $form-feedback-margin-top;
    @include font-size($form-feedback-font-size);
    color: $color;
  }

  .#{$state}-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%; // Contain to parent when possible
    padding: $form-feedback-tooltip-padding-y $form-feedback-tooltip-padding-x;
    margin-top: 0.063rem;
    @include font-size($form-feedback-tooltip-font-size);
    line-height: $form-feedback-tooltip-line-height;
    color: color-yiq($color);
    background-color: rgba($color, $form-feedback-tooltip-opacity);
    @include border-radius($form-feedback-tooltip-border-radius);
  }

  .mcf-form-switcher,
  .mcf-form-check-list {
    @include form-validation-state-selector($state) {
      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: flex;

        [class^='icon-']::before,
        [class*=' icon-']::before {
          margin-right: $form-feedback-icon-margin-right;
          margin-left: 0;
        }
      }
    }
  }

  .mcf-form-control,
  .mcf-form-control-wrapper,
  .mcf-textbox {
    @include form-validation-state-selector($state) {
      border-color: $color;

      @if $enable-validation-icons {
        padding-right: $input-height-inner;
        background-image: $icon;
        background-repeat: no-repeat;
        background-position: right $input-height-inner-quarter center;
        background-size: $input-height-inner-half $input-height-inner-half;
      }

      &:focus {
        border-color: $color;
        box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: flex;

        [class^='icon-']::before,
        [class*=' icon-']::before {
          margin-right: $form-feedback-icon-margin-right;
          margin-left: 0;
        }
      }
    }
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  textarea.mcf-form-control {
    @include form-validation-state-selector($state) {
      @if $enable-validation-icons {
        padding-right: $input-height-inner;
        background-position: top $input-height-inner-quarter right $input-height-inner-quarter;
      }
    }
  }

  .mcf-form-select {
    @include form-validation-state-selector($state) {
      border-color: $color;

      @if $enable-validation-icons {
        padding-right: $form-select-feedback-icon-padding-right;
        background: $form-select-background,
          $icon
            $form-select-bg
            no-repeat
            $form-select-feedback-icon-position /
            $form-select-feedback-icon-size;
      }

      &:focus {
        border-color: $color;
        box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: flex;

        [class^='icon-']::before,
        [class*=' icon-']::before {
          margin-right: $form-feedback-icon-margin-right;
          margin-left: 0;
        }
      }
    }
  }

  .mcf-form-control-file {
    @include form-validation-state-selector($state) {
      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: flex;

        [class^='icon-']::before,
        [class*=' icon-']::before {
          margin-right: $form-feedback-icon-margin-right;
          margin-left: 0;
        }
      }
    }
  }

  .mcf-form-check-list {
    @include form-validation-state-selector($state) {
      .mcf-form-check__input {
        border-color: $color;

        &:checked {
          background-color: lighten($color, 10%);
        }

        &:focus {
          box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
        }

        ~ .mcf-form-check__label {
          color: $color;
        }

        ~ .#{$state}-feedback,
        ~ .#{$state}-tooltip {
          display: flex;

          [class^='icon-']::before,
          [class*=' icon-']::before {
            margin-right: $form-feedback-icon-margin-right;
            margin-left: 0;
          }
        }
      }
    }
  }

  .mcf-form-check__input {
    @include form-validation-state-selector($state) {
      border-color: $color;

      &:checked {
        background-color: lighten($color, 10%);
      }

      &:focus {
        box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
      }

      ~ .mcf-form-check__label {
        color: $color;
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: flex;

        [class^='icon-']::before,
        [class*=' icon-']::before {
          margin-right: $form-feedback-icon-margin-right;
          margin-left: 0;
        }
      }
    }
  }
  .mcf-form-check--inline .mcf-form-check__input {
    ~ .#{$state}-feedback {
      margin-left: 0.5em;
    }
  }

  // custom file
  .mcf-form-file__input {
    @include form-validation-state-selector($state) {
      ~ .mcf-form-file__label {
        border-color: $color;
      }

      ~ .#{$state}-feedback,
      ~ .#{$state}-tooltip {
        display: flex;

        [class^='icon-']::before,
        [class*=' icon-']::before {
          margin-right: $form-feedback-icon-margin-right;
          margin-left: 0;
        }
      }

      &:focus {
        ~ .mcf-form-file__label {
          border-color: $color;
          box-shadow: 0 0 0 $input-focus-width rgba($color, 0.25);
        }
      }
    }
  }
}
