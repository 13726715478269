@mixin headings {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .mcf-h1,
  .mcf-h2,
  .mcf-h3,
  .mcf-h4,
  .mcf-h5,
  .mcf-h6,
  .mcf-display-1,
  .mcf-display-2,
  .mcf-display-3,
  .mcf-display-4 {
    @content;
  }
}
