// # SVG COLOR
// ==========================================================================
@mixin bulle-variant-color($parent, $color: false) {
  @if ($color != false) {
    #{$parent} {
      color: color-yiq($color);
      background-image: url(svg-color-string-modifier($color)); /* stylelint-disable function-url-quotes */
      background-repeat: no-repeat;
      background-position: center;
      background-clip: content-box;
    }
  }
}
