// stylelint-disable declaration-no-important
// Valeur communes
// stylelint-disable-next-line scss/dollar-variable-default
$positions: static,
  relative,
  absolute,
  fixed,
  sticky;

@each $position in $positions {
  .mcf-position--#{$position} {
    position: $position !important;
  }
}

// Shorthand
.mcf-fixed--top {
  position: fixed !important;
  top: 0;
  right: 0;
  left: 0;
  z-index: $zindex-fixed;
}

.mcf-fixed--bottom {
  position: fixed !important;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $zindex-fixed;
}

.mcf-fixed--right {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-fixed;
}

.mcf-sticky--top {
  @supports (position: sticky) {
    position: sticky !important;
    top: 0;
    z-index: $zindex-sticky;
  }
}

.mcf-absolute--top-left {
  top: 0;
  left: 0;
}

.mcf-absolute--top-right {
  top: 0;
  right: 0;
}

.mcf-absolute--bottom-left {
  bottom: 0;
  left: 0;
}

.mcf-absolute--bottom-right {
  right: 0;
  bottom: 0;
}
