.mcf-form-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: $form-file-height;
  margin-bottom: 0;
}

.mcf-form-file__input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: $form-file-height;
  margin: 0;
  opacity: 0;

  &:focus ~ .mcf-form-file__label {
    border-color: $form-file-focus-border-color;
    box-shadow: $form-file-focus-box-shadow;
  }

  // Use disabled attribute instead of :disabled pseudo-class
  // Workaround for: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/11295231
  &[disabled] ~ .mcf-form-file__label .mcf-form-file__text {
    background-color: $form-file-disabled-bg;
  }
}

.mcf-form-file__label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  height: $form-file-height;
  border-color: $form-file-border-color;
  @include border-radius($form-file-border-radius);
  @include box-shadow($form-file-box-shadow);
}

.mcf-form-file__text {
  display: block;
  flex-grow: 1;
  padding: $form-file-padding-y $form-file-padding-x;
  overflow: hidden;
  font-family: $form-file-font-family;
  font-weight: $form-file-font-weight;
  line-height: $form-file-line-height;
  color: $form-file-color;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: $form-file-bg;
  border-color: inherit;
  border-style: solid;
  border-width: $form-file-border-width;
  @include border-left-radius(inherit);
}

.mcf-form-file__button {
  display: block;
  flex-shrink: 0;
  padding: $form-file-padding-y $form-file-padding-x;
  margin-left: -$form-file-border-width;
  line-height: $form-file-line-height;
  color: $form-file-button-color;
  @include gradient-bg($form-file-button-bg);
  border-color: inherit;
  border-style: solid;
  border-width: $form-file-border-width;
  @include border-right-radius(inherit);
}
