// stylelint-disable selector-no-qualifying-type

// Donner au bouton le même comportement qu'une div
.mcf-btn-group,
.mcf-btn-group--vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;

  > .mcf-btn {
    position: relative;
    flex: 0 1 auto;

    @include hover () {
      z-index: 1;
    }
    &:focus,
    &:active,
    &.active {
      z-index: 1;
    }
  }

  // Evites la double bordure quand deux boutons sont cote à cote
  .mcf-btn + .mcf-btn,
  .mcf-btn + .mcf-btn-group,
  .mcf-btn-group + .mcf-btn,
  .mcf-btn-group + .mcf-btn-group {
    margin-left: -$btn-border-width;
  }
}

// Optionel: Grouper plusieurs boutons ensemble
.mcf-btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .mcf-input-group {
    width: auto;
  }
}

.mcf-btn-group {
  > .mcf-btn:first-child {
    margin-left: 0;
  }

  // Remise des angles arrondies
  > .mcf-btn:not(:last-child):not(.mcf-dropdown-toggle),
  > .mcf-btn-group:not(:last-child) > .mcf-btn {
    @include border-right-radius(0);
  }

  > .mcf-btn:not(:first-child),
  > .mcf-btn-group:not(:first-child) > .mcf-btn {
    @include border-left-radius(0);
  }

  &.mcf-btn-group--gutter {
    margin-right: -1em;
    margin-left: -1em;

    // Remise des angles arrondies
    > .mcf-btn:not(:last-child):not(.mcf-dropdown-toggle),
    > .mcf-btn-group:not(:last-child) > .mcf-btn {
      @include border-right-radius($btn-border-radius);
    }

    > .mcf-btn:not(:first-child),
    > .mcf-btn-group:not(:first-child) > .mcf-btn {
      @include border-left-radius($btn-border-radius);
    }

    &.mcf-btn-group--2 {
      @extend .mcf-form-switcher--2;
    }

    &.mcf-btn-group--3 {
      @extend .mcf-form-switcher--3;
    }

    &.mcf-btn-group--6 {
      @extend .mcf-form-switcher--6;
    }
  }
}

// Taille

.mcf-btn-group--sm > .mcf-btn { @extend .mcf-btn--sm; }
.mcf-btn-group--lg > .mcf-btn { @extend .mcf-btn--lg; }


//
// Séparation des boutons et du dropdown
//

.mcf-dropdown-toggle-split {
  padding-right: $btn-padding-x * .75;
  padding-left: $btn-padding-x * .75;

  &::after,
  .mcf-dropup &::after,
  .mcf-dropright &::after {
    margin-left: 0;
  }

  .mcf-dropleft &::before {
    margin-right: 0;
  }
}

.mcf-btn--sm + .mcf-dropdown-toggle-split {
  padding-right: $btn-padding-x-sm * .75;
  padding-left: $btn-padding-x-sm * .75;
}

.mcf-btn--lg + .mcf-dropdown-toggle-split {
  padding-right: $btn-padding-x-lg * .75;
  padding-left: $btn-padding-x-lg * .75;
}

.mcf-btn-group.show .mcf-dropdown-toggle {
  @include box-shadow($btn-active-box-shadow);

  // Suppression de l'ombre
  &.mcf-btn--link {
    @include box-shadow(none);
  }
}


//
// Groupement de boutons verticaux
//

.mcf-btn-group--vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  .mcf-btn,
  .mcf-btn-group {
    width: 100%;
  }

  > .mcf-btn + .mcf-btn,
  > .mcf-btn + .mcf-btn-group,
  > .mcf-btn-group + .mcf-btn,
  > .mcf-btn-group + .mcf-btn-group {
    margin-top: -$btn-border-width;
    margin-left: 0;
  }

  > .mcf-btn:not(:last-child):not(.mcf-dropdown-toggle),
  > .mcf-btn-group:not(:last-child) > .mcf-btn {
    @include border-bottom-radius(0);
  }

  > .mcf-btn:not(:first-child),
  > .mcf-btn-group:not(:first-child) > .mcf-btn {
    @include border-top-radius(0);
  }
}


// Checkbox and radio options
//
// In order to support the browser's form validation feedback, powered by the
// `required` attribute, we have to "hide" the inputs via `clip`. We cannot use
// `display: none;` or `visibility: hidden;` as that also hides the popover.
// Simply visually hiding the inputs via `opacity` would leave them clickable in
// certain cases which is prevented by using `clip` and `pointer-events`.
// This way, we ensure a DOM element is visible to position the popover from.
//
// See https://github.com/twbs/bootstrap/pull/12794 and
// https://github.com/twbs/bootstrap/pull/14559 for more information.

.mcf-btn-group-toggle {
  > .mcf-btn,
  > .mcf-btn-group > .mcf-btn {
    margin-bottom: 0; // Override default `<label>` value

    input[type="radio"],
    input[type="checkbox"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }
}
