// Button variants
//
// Pomper les styles par défaut, ainsi que :hover, :focus, :active,
// et les options désactivées pour tous les boutons

@mixin button-variant($background, $border, $hover-background: darken($background, 10%), $hover-border: darken($border, 10%), $active-background: darken($background, 20%), $active-border: darken($border, 20%)) {
  color: color-yiq($background);
  background-color: $background;
  border-color: $border;
  @include box-shadow($btn-box-shadow);

  @include hover {
    color: color-yiq($hover-background);
    background-color: $hover-background;
    border-color: $hover-border;
  }

  &:focus,
  &.focus {
    @include box-shadow($btn-box-shadow);
    color: color-yiq($hover-background);
    background-color: $hover-background;
    border-color: $hover-border;
  }

  // Disabled vient en premier si actif peut correctement le relooker
  &.disabled,
  &:disabled {
    color: color-yiq($background);
    background-color: $background;
    border-color: $border;
    // Supprimer les dégradés CSS s'ils sont activés
    @if $enable-gradients {
      background-image: none;
    }
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    @if $enable-gradients {
      background-image: none; // Supprimer le dégradé pour l'état pressed/active
    }
    border-color: $active-border;

    &:focus {
      box-shadow: none;
    }
  }
}

@mixin button-outline-variant($color, $color-hover: color-yiq($color), $hover-background: $color, $hover-border: $color, $active-background: darken($color, 10%), $active-border: darken($color, 10%)) {
  color: $color;
  background-color: transparent;
  background-image: none;
  border-color: $color;

  &:hover {
    color: $color-hover;
    background-color: $hover-background;
    border-color: $hover-border;
  }

  &:focus,
  &.focus {
    box-shadow: none;
  }

  &.disabled,
  &:disabled {
    color: $color;
    background-color: transparent;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active,
  .show > &.dropdown-toggle {
    color: color-yiq($active-background);
    background-color: $active-background;
    border-color: $active-border;

    &:focus {
      box-shadow: none;
    }
  }
}

// Taille des bouttons
@mixin button-size($padding-y, $padding-x, $font-size, $line-height, $border-radius) {
  padding: $padding-y $padding-x;
  @include font-size($font-size);
  line-height: $line-height;
  // Déclarer manuellement pour fournir un remplacement à la valeur par défaut du navigateur
  @if $enable-rounded {
    border-radius: $border-radius;
  } @else {
    border-radius: 0;
  }
}
