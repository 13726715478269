.mcf-bubble {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 8.75em;
  height: 8.75em;

  .mcf-bubble__text {
    display: flex;
    flex-direction: column;
    .mcf-bubble__title{
      margin-top: .4em;
      @extend h3;
      font-size: 2.25em;
    }
  }
}

@each $color, $value in $colors {
  @include bulle-variant-color(".mcf-bubble--left--#{$color}", $value);
}
