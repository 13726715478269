.mcf-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  //left: -100%;
  z-index: $sidebar-z-index;
  width: 0;
  //width: 100%;
  height: 100vh;
  margin-left: 0;
  overflow-y: auto;
  -ms-overflow-style: none;
  line-height: $line-height-base;
  background: $sidebar-background;
  @include transition($sidebar-transition);

  &.mcf-sidebar--reverse {
    right: 0;
    //right: -100%;
    left: inherit;
    margin-right: 0;
    margin-left: inherit;

    .mcf-sidebar__close {
      left: 0.625rem;
    }

    /*&.show {
      //width: 100%;
      right: 0;
      left: inherit;
    }*/
  }

  &.show {
    width: 100%;
    //left: 0;
  }

  .mcf-sidebar__close {
    position: absolute;
    top: 0.625rem;
    right: 0.625rem;
    z-index: 1000;
    cursor: pointer;
    .icon {
      font-size: $sidebar-close-font-size-icon;
    }
  }

  .mcf-sidebar__content {
    position: relative;
    min-height: 100vh;
    overflow: hidden;
    color: $sidebar-content-color;
    @include padding(5);
  }
}
