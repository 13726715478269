//
// Check/radio
//

.mcf-form-check {
  display: flex;
  align-items: center;
  min-height: $form-check-min-height;
  padding-left: $form-check-padding-left;

  > .mcf-form-group,
  > .mcf-form-control,
  > .mcf-form-control-wrapper > .mcf-form-control {
    margin-left: $form-check-input-telephone-margin-left;
  }
}

.mcf-form-check__input {
  float: left;
  width: $form-check-input-width;
  height: $form-check-input-width;
  // Todo: Change static value to base line-height?
  margin-top: calc((1.5em - #{$form-check-input-width}) / 2); // line-height minus check height
  margin-left: $form-check-padding-left * -1;
  background-color: $form-check-input-bg;
  border: $form-check-input-border;
  appearance: none;

  &[type="checkbox"] {
    @include border-radius($form-check-input-border-radius);
  }

  &[type="radio"] {
    @include border-radius($form-check-radio-border-radius);
  }

  &:focus {
    border-color: $form-check-input-focus-border;
    outline: 0;
    box-shadow: $form-check-input-focus-box-shadow;
  }

  &:hover:not([disabled]) {
    border-color: $form-check-input-hover-border;
  }

  &:checked {
    background-repeat: $form-check-input-checked-bg-repeat;
    background-position: $form-check-input-checked-bg-position;
    background-size: $form-check-input-checked-bg-size;
    border-color: $form-check-input-checked-border-color;

    &[type="checkbox"] {
      background-color: $form-check-input-checked-bg-color;
      background-image: $form-check-input-checked-bg-image;
    }

    &[type="radio"] {
      background-color: $form-check-radio-checked-bg-color;
      background-image: $form-check-radio-checked-bg-image;
    }
  }

  &[type="checkbox"]:indeterminate {
    background-color: $form-check-input-indeterminate-bg-color;
    background-image: $form-check-input-indeterminate-bg-image;
    background-repeat: $form-check-input-indeterminate-bg-repeat;
    background-position: $form-check-input-indeterminate-bg-position;
    background-size: $form-check-input-indeterminate-bg-size;
    border-color: $form-check-input-indeterminate-border-color;
  }

  // Use disabled attribute instead of :disabled pseudo-class
  // Workaround for: https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/11295231
  &[disabled] {
    pointer-events: none;
    background-color: $form-check-input-disabled-bg;
    filter: none;
    opacity: .5;

    ~ .mcf-form-check__label {
      opacity: .5;
    }
  }
}

.mcf-form-check__label {
  margin-bottom: 0;
  margin-left: $form-check-label-margin-left;
}

.mcf-form-check-list {
  .mcf-form-check {
    margin-bottom: $form-check-margin-bottom;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.mcf-form-check-list--inline {
  .mcf-form-check {
    display: inline-block;
    margin-right: $form-check-inline-margin-right;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  }
}
