@mixin avatar-variant($color, $value) {
  &.active.mcf-avatar--#{$color} {
    .mcf-avatar__body {
      color: $white;
      background-color: $value;
      box-shadow: $avatar-box-shadow rgba($value, 0.5);
      opacity: 1;
    }

    .mcf-avatar__desc {
      .mcf-avatar__desc-title {
        color: $value;
      }
    }
  }

  &.mcf-avatar--#{$color}:hover,
  &.mcf-avatar--#{$color}:focus,
  &.mcf-avatar--#{$color}:active {
    .mcf-avatar__body {
      color: $white;
      background-color: darken($value, 10%);
      box-shadow: $avatar-box-shadow rgba($value, 0.5);
      opacity: 1;
    }

    .mcf-avatar__desc {
      .mcf-avatar__desc-title {
        color: darken($value, 10%);
      }
    }
  }
}
