// Checkboxes and radios
//
// La classe de base prend en charge tous les aspects comportementaux clés.

.mcf-custom-control {
  display: flex;
  min-height: $line-height-base;
}

.mcf-custom-control--inline {
  display: inline-flex;
  margin-right: $custom-control-spacer-x;
}

.mcf-custom-control__input {
  z-index: -1; // Place l'input derrière le label pour ne pas superposer le texte
  display: none;
  opacity: 0;

  &:not(:disabled):not(.disabled):hover ~ .mcf-custom-control__label::before {
    border: solid 1px $custom-control-indicator-hover-border;
  }

  &:checked ~ .mcf-custom-control__label::before {
    color: $custom-control-indicator-checked-color;
    @include gradient-bg($custom-control-indicator-checked-bg);
    border: solid 1px $custom-control-indicator-checked-bg;
  }

  &:active ~ .mcf-custom-control__label::before {
    color: $custom-control-indicator-active-color;
    background-color: $custom-control-indicator-active-bg;
  }

  &:disabled {
    ~ .mcf-custom-control__label {
      color: $custom-control-label-disabled-color;

      &::before {
        background-color: $custom-control-indicator-disabled-bg;
      }
    }
  }
}

// Custom control
//
// Construire les contrôles personnalisés via les pseudo-elements.

.mcf-custom-control__label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
  cursor: pointer;
  // Background-color
  &::before {
    min-width: $custom-control-indicator-size;
    max-width: $custom-control-indicator-size;
    min-height: $custom-control-indicator-size;
    max-height: $custom-control-indicator-size;
    @include margin-right(3);
    pointer-events: none;
    cursor: pointer;
    content: "";
    user-select: none;
    background-color: $custom-control-indicator-bg;
    border: solid 1px $input-border-color;
  }
  // Foreground (icon)
  &::after {
    position: absolute;
    display: block;
    min-width: $custom-control-indicator-size;
    max-width: $custom-control-indicator-size;
    min-height: $custom-control-indicator-size;
    max-height: $custom-control-indicator-size;
    padding: $input-border-width;
    cursor: pointer;
    content: "";
    background-repeat: no-repeat;
    background-position: center center;
    background-size: $custom-control-indicator-bg-size;
  }
}

// Checkboxes

.mcf-custom-checkbox {
  .mcf-custom-control__label::before {
    @include border-radius($custom-checkbox-indicator-border-radius);
  }

  .mcf-custom-control__input:checked ~ .mcf-custom-control__label {
    &::before {
      @include gradient-bg($custom-control-indicator-checked-bg);
    }

    &::after {
      background-image: $custom-checkbox-indicator-icon-checked;
    }
  }

  .mcf-custom-control__input:indeterminate ~ .mcf-custom-control__label {
    &::before {
      @include gradient-bg($custom-checkbox-indicator-indeterminate-bg);
    }

    &::after {
      background-image: $custom-checkbox-indicator-icon-indeterminate;
    }
  }

  .mcf-custom-control__input:disabled {
    &:checked ~ .mcf-custom-control__label::before {
      background-color: $custom-control-indicator-checked-disabled-bg;
    }

    &:indeterminate ~ .mcf-custom-control__label::before {
      background-color: $custom-control-indicator-checked-disabled-bg;
    }
  }
}

// Radios

.mcf-custom-radio {
  .mcf-custom-control__label::before {
    border-radius: $custom-radio-indicator-border-radius;
  }

  .mcf-custom-control__input:checked ~ .mcf-custom-control__label {
    &::before {
      @include gradient-bg($custom-control-indicator-checked-bg);
    }

    &::after {
      background-image: $custom-radio-indicator-icon-checked;
    }
  }

  .mcf-custom-control__input:disabled {
    &:checked ~ .custom-control__label::before {
      background-color: $custom-control-indicator-checked-disabled-bg;
    }
  }
}
// Switcher

.mcf-custom-control--switcher {
  display: inline-block;
  margin-bottom: $custom-switcher-margin-bottom;
  position: relative;

  &::before {
    position: relative;
    top: ($custom-control-indicator-size / 2);
    left: ($custom-control-indicator-size / 2);
    display: block;
    width: $custom-control-indicator-size;
    height: $custom-control-indicator-size;
    margin-left: auto;
    cursor: pointer;
    content: none;
    background-color: $vert-menthe;
    background-image: $custom-checkbox-indicator-icon-checked;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: $custom-control-indicator-bg-size;
    border: solid 2px $white;
    border-radius: 50%;
  }

  .mcf-form-control{
    display: none;
  }

  .mcf-custom-control__label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-weight: $btn-font-weight;
    text-align: center;
    white-space: normal;
    vertical-align: middle;
    user-select: none;
    border: $btn-border-width solid transparent;
    @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
    @include transition($btn-transition);
    @include hover-focus {
      text-decoration: none;
    }

    &--rounded {
      border-radius: $custom-switcher-border-rounded;
    }

    &.focus,
    &:focus {
      outline: 0;
      box-shadow: $btn-focus-box-shadow;
    }

    &.disabled,
    &:disabled {
      opacity: $btn-disabled-opacity;
      @include box-shadow(none);
    }

    &:not(:disabled):not(.disabled) {
      cursor: pointer;
    }

    &:not(:disabled):not(.disabled).active,
    &:not(:disabled):not(.disabled):active {
      background-image: none;
      @include box-shadow($btn-box-shadow);

      &:focus {
        @include box-shadow($btn-box-shadow);
      }
    }
  }
}

.mcf-custom-switcher {
  .mcf-custom-control__label {
    color: color-yiq($custom-switcher-bg);
    background-color: $custom-switcher-bg;
    border-color: $custom-switcher-border-color;

    @include hover {
      color: color-yiq($custom-switcher-hover-bg);
      background-color: $custom-switcher-hover-bg;
      border-color: $custom-switcher-hover-border-color;
    }

    // Disabled vient en premier si actif peut correctement le relooker
    &.disabled,
    &:disabled {
      color: color-yiq($input-disabled-bg);
      background-color: $input-disabled-bg;
      border-color: $custom-switcher-border-color;
    }
  }

  .mcf-custom-control__label::before {
    content: none;
  }

  .mcf-custom-control__label::after {
    content: none;
  }

  &--block {
    display: block;
    width: 100%;

    .mcf-custom-control__input:checked ~ .mcf-custom-control__label {
      color: color-yiq($custom-switcher-checked-bg);
      background-color: $custom-switcher-checked-bg;
      @if $enable-gradients {
        background-image: none; // Supprimer le dégradé pour l'état pressed/active
      }
      border-color: $custom-switcher-checked-border-color;

      @include hover {
        color: color-yiq($custom-switcher-checked-hover-bg);
        background-color: $custom-switcher-checked-hover-bg;
        border-color: $custom-switcher-checked-hover-border-color;
      }

      &::before{
        position: absolute;
        top: -($custom-control-switcher-indicator-size / 3);
        right: -1em;
        display: block;
        min-width: $custom-control-switcher-indicator-size;
        max-width: $custom-control-switcher-indicator-size;
        min-height: $custom-control-switcher-indicator-size;
        max-height: $custom-control-switcher-indicator-size;
        margin-left: auto;
        cursor: pointer;
        content: "";
        background-color: $vert-menthe;
        background-image: $custom-checkbox-indicator-icon-checked;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: $custom-control-switcher-indicator-icon-size;
        border: solid 2px $white;
        border-radius: 50%;
      }

      &:not(:disabled):not(.disabled):active,
      &:not(:disabled):not(.disabled).active {
        &:focus {
          color: color-yiq($custom-switcher-focus-bg);
          background-color: $custom-switcher-focus-bg;
          border-color: $custom-switcher-focus-border-color;
        }
      }
    }
  }

  .mcf-custom-control__input:checked ~ .mcf-custom-control__label {
    color: color-yiq($custom-switcher-checked-bg);
    background-color: $custom-switcher-checked-bg;
    @if $enable-gradients {
      background-image: none; // Supprimer le dégradé pour l'état pressed/active
    }
    border-color: $custom-switcher-checked-border-color;

    @include hover {
      color: color-yiq($custom-switcher-checked-hover-bg);
      background-color: $custom-switcher-checked-hover-bg;
      border-color: $custom-switcher-checked-hover-border-color;
    }

    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      &:focus {
        color: color-yiq($custom-switcher-focus-bg);
        background-color: $custom-switcher-focus-bg;
        border-color: $custom-switcher-focus-border-color;
      }
    }
  }

  .mcf-custom-control__input:disabled {
    &:checked ~ .custom-control__label {
      opacity: $btn-disabled-opacity;
    }
  }
}
// Select

.mcf-custom-select {
  display: inline-block;
  width: 100%;
  height: $custom-select-height;
  padding: $custom-select-padding-y ($custom-select-padding-x + $custom-select-indicator-padding) $custom-select-padding-y $custom-select-padding-x;
  line-height: $custom-select-line-height;
  color: $custom-select-color;
  vertical-align: middle;
  background: $custom-select-bg $custom-select-indicator no-repeat right $custom-select-padding-x center;
  background-size: $custom-select-bg-size;
  border: $custom-select-border-width solid $custom-select-border-color;
  @if $enable-rounded {
    border-radius: $custom-select-border-radius;
  } @else {
    border-radius: 0;
  }
  appearance: none;

  &:hover {
    border-color: $custom-select-hover-border-color;
  }

  &:focus {
    border-color: $custom-select-focus-border-color;
    outline: 0;

    &::-ms-value {
      // Pour une cohérence visuelle avec d'autres plateformes / navigateurs,
      // supprimer le texte blanc par défaut sur la mise en évidence de fond bleu donné au
      // texte d'option sélectionné lorsque le <select> (toujours fermé) reçoit le focus
      // dans IE et (sous certaines conditions) Edge.
      // cf. https://github.com/twbs/bootstrap/issues/19398.
      color: $input-color;
      background-color: $input-bg;
    }
  }

  &[multiple],
  &[size]:not([size="1"]) {
    height: auto;
    padding-right: $custom-select-padding-x;
    background-image: none;
  }

  &:disabled {
    color: $custom-select-disabled-color;
    background-color: $custom-select-disabled-bg;
  }
  // Masque le curseur par défaut dans IE11
  &::-ms-expand {
    opacity: 0;
  }
}

.mcf-custom-select--sm {
  height: $custom-select-height-sm;
  padding-top: $custom-select-padding-y;
  padding-bottom: $custom-select-padding-y;
  font-size: $custom-select-font-size-sm;
}

.mcf-custom-select--lg {
  height: $custom-select-height-lg;
  padding-top: $custom-select-padding-y;
  padding-bottom: $custom-select-padding-y;
  font-size: $custom-select-font-size-lg;
}
// File

.mcf-custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: $custom-file-height;
  margin-bottom: 0;
}

.mcf-custom-file__input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: $custom-file-height;
  margin: 0;
  opacity: 0;

  &:focus ~ .custom-file__label {
    border-color: $custom-file-focus-border-color;

    &::after {
      border-color: $custom-file-focus-border-color;
    }
  }

  &:hover ~ .custom-file__label {
    border-color: $custom-file-focus-border-color;

    &::after {
      border-color: $custom-file-focus-border-color;
    }
  }
  @each $lang, $value in $custom-file-text {
    &:lang(#{$lang}) ~ .custom-file__label::after {
      content: $value;
    }
  }
}

.mcf-custom-file__label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: $custom-file-height;
  padding: $custom-file-padding-y $custom-file-padding-x;
  line-height: $custom-file-line-height;
  color: $custom-file-color;
  background-color: $custom-file-bg;
  border: $custom-file-border-width solid $custom-file-border-color;
  @include border-radius($custom-file-border-radius);
  @include box-shadow($custom-file-box-shadow);

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(#{$custom-file-height} - #{$custom-file-border-width} * 2);
    padding: $custom-file-padding-y $custom-file-padding-x;
    line-height: $custom-file-line-height;
    color: $custom-file-button-color;
    content: "Parcourir";
    @include gradient-bg($custom-file-button-bg);
    border-left: $custom-file-border-width solid $custom-file-border-color;
    @include border-radius(0 $custom-file-border-radius $custom-file-border-radius 0);
  }
}

.mcf-upload {

  &__button {
    @include border-radius($upload-button-border-radius);
    width: $upload-button-width;
    padding: $upload-button-padding;
    font-size: $upload-button-font-size;
    color: $white;
    cursor: pointer;
    @include margin-right($upload-button-margin-right);
  }

  &__file {
    display: flex;
    @include padding(2, 3);
    @include hover-focus {
      background-color: $bleu-macif-instit;
    }
    justify-content: space-between;
    background-color: $gris-bg;
    border: $upload-file-border;
  }

  &__label {
    @include padding($upload-label-padding);
    @include hover-focus {
      cursor: pointer;
      outline: none;
    }
    font-weight: $upload-label-font-weight;
    background-color: $gris-sable;
  }
}

// Range
.mcf-custom-range {
  line-height: 0;
  .mcf-custom-range__input {
    @include input-color-effects($bleu-fonce);
    box-sizing: border-box;       // même modèle de boîte pour tous
    width: 100%;
    height: $range-thumb-size;
    padding: 0;                   // nécessaire pour IE
    font: inherit;                // même rendu suivant font document
    cursor: pointer;
    background: transparent;      // sert pour couleur de fond de la zone de déplacement
    border: none !important;      // TODO pour contrer le css macif
    outline: none;
    opacity: .8;
    -webkit-appearance: none;     // nécessaire pour Chrome FIXME: use mixin
    @include hover-focus {
      border:none !important;     // FIXME: pour contrer le css macif
    }

    // chrome
    &::-webkit-slider-runnable-track {
      height: $range-track-height;
      background-color: $gris-sable;
      border-radius: $range-track-height;
    }

    &::-webkit-slider-thumb {
      width: $range-thumb-size;
      height: $range-thumb-size;
      padding: 0;
      margin-top: -$range-thumb-size / 2 + $range-track-height / 2;
      background: $range-thumb-background;
      background-color: currentColor;
      background-size: 50%;
      border: $range-thumb-border;
      border-radius: 50%;
      -webkit-appearance: none;       // également nécessaire sur le curseur FIXME: use mixin
    }

    //firefox
    &::-moz-range-track {
      height: $range-track-height;
      background-color: $gris-sable;
      border-radius: $range-track-height;
    }

    &::-moz-range-thumb {
      width: $range-thumb-size;
      height: $range-thumb-size;
      padding: 0;
      background: $range-thumb-background;
      background-color: currentColor;
      background-size: 50%;
      border: $range-thumb-border;
      border-radius: 50%;
    }

    &::-moz-range-progress {
      height: 0;
      background: transparent;        // supprime barre progression avant
    }

    // ie edge
    &::-ms-track {
      height: $range-track-height;
      color: transparent;             // supprime les graduations pour IE
      background-color: $gris-sable;
      border: none;
      border-radius: $range-track-height;
    }

    &::-ms-thumb {
      width: $range-thumb-size / 2;
      height: $range-thumb-size / 2;
      padding: 0;
      background: $range-thumb-background;
      background-color: currentColor;
      background-size: 50%;
      border: $range-thumb-border;
      border-radius: 50%;
    }

    &::-ms-tooltip {
      display: none;                  // supprime l'affichage de la valeur au survol
    }

    &::-ms-fill-lower {
      background: transparent;
    }

    &::-ms-fill-upper {
      background: transparent;
    }
  }

  &:active .mcf-custom-range__bubble {
    display: block !important; // FIXME: no important
  }

  &:hover .mcf-custom-range__bubble {
    display: block !important; // FIXME: no important
  }

  .mcf-custom-range__bubble-rail {
    position: relative;
    height: 0;
    margin-right: $range-thumb-size / 2;
    margin-left: $range-thumb-size / 2;
    .mcf-custom-range__bubble {
      position: absolute;
      top: -$range-thumb-size;
      display: none;
      width: max-content;
      height: $range-bubble-height;
      padding: $range-bubble-padding;
      line-height: $line-height-base;
      color: $white;
      background-color: $bleu-macif-instit;
      border-radius: 1.25rem;
      transform: translate(-50%, 0);
    }
  }

  .mcf-custom-range__graduations {
    position: relative;
    padding-top: $range-marker-text-marker-space;
    margin-right: $range-thumb-size / 2;
    margin-bottom: $range-graduations-block-height;
    margin-left: $range-thumb-size / 2;
    line-height: $line-height-base;
    .mcf-custom-range__graduation-marker {
      width: $range-marker-width;
      background-color: $range-marker-color;
    }
    .mcf-custom-range__graduation {
      position: absolute;
    }
    .mcf-custom-range__graduation-small {
      .mcf-custom-range__graduation-marker {
        height: $range-marker-small-height;
      }
    }
    .mcf-custom-range__graduation-large {
      .mcf-custom-range__graduation-marker {
        height: $range-marker-large-height;
      }
      .mcf-custom-range__graduation-text {
        width: max-content;
        margin-top: $range-marker-text-marker-space;
        transform: translate(-50%, 0);
      }
    }
  }
}
