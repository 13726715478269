.mcf-avatar {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  width: auto;
  cursor: pointer;

  .mcf-avatar__body {
    width: $avatar-size;
    height: $avatar-size;
    @include border-radius(50%);
    font-family: $font-family-regular;
    font-size: $avatar-font-size;
    color: color-yiq($gris-fonce);
    background-color: $gris-fonce;
    border: $avatar-border;
    box-shadow: $avatar-box-shadow rgba($gris-fonce, 0.5);
    opacity: 0.2;
    transition: all 0.8s ease;

    @include media-breakpoint-up(md) {
      font-size: $avatar-font-size-md;
    }

    .mcf-avatar__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      text-align: center;

      * {
        font-size: $avatar-body-font-size;
      }
    }
  }

  @each $color, $value in $colors {
    @include avatar-variant($color, $value);
  }

  .mcf-avatar__desc {
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
    min-width: $avatar-min-width;
    @include padding-top($avatar-desc-padding);

    .mcf-avatar__desc-title {
      font-family: $font-family-regular;
      font-size: $avatar-content-font-size-title;
      line-height: $avatar-content-lineheight-title;
    }

    .mcf-avatar__desc-subtitle {
      font-family: $font-family-base;
      font-size: $avatar-content-font-size-desc;
      font-style: italic;
      line-height: $avatar-content-lineheight-subtitle;
    }
  }

  &.mcf-avatar--small {
    .mcf-avatar__body {
      font-size: $avatar-font-size-small;
    }
  }

  &.mcf-avatar--left {
    flex-direction: row;

    .mcf-avatar__desc {
      @include margin-left(5);
      @include padding-top(0);
      align-items: start;
      text-align: left;
    }
  }

  &.mcf-avatar--right {
    flex-direction: row-reverse;

    .mcf-avatar__desc {
      @include margin-right(5);
      @include padding-top(0);
      align-items: end;
      text-align: right;
    }
  }

  &.mcf-avatar--top {
    flex-direction: column-reverse;

    .mcf-avatar__desc {
      @include padding-top(0);
      @include margin-bottom(2);
    }
  }

  /*&:not(.active) {
    filter: grayscale(100%);
    opacity: .4;

    &:hover {
      filter: inherit;
      opacity: 1;
    }
  } */
}
