.mcf-tunnel__header-main {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: $tunnel-padding;

    .mcf-tunnel__header-logo {
        margin: $tunnel-margin-header-logo;
    }

    .mcf-tunnel__header-title {
    }

    .mcf-tunnel__header-link {
        margin: $tunnel-margin-header-link;
    }
}
