// stylelint-disable declaration-no-important

// Width and height

@each $prop, $abbrev in (width: w, height: h) {
  @each $size, $length in $sizes {
    .mcf-#{$abbrev}--#{$size} { #{$prop}: $length !important; }
  }
}

.mcf-mw--100 { max-width: 100% !important; }
.mcf-mh--100 { max-height: 100% !important; }

.mcf-width--inherit {
  width: inherit !important;
}

