.mcf-overflow--auto {
  overflow: auto;
}
.mcf-overflow--hidden {
  overflow: hidden;
}
.mcf-overflow--inherit {
  overflow: inherit;
}
.mcf-overflow--initial {
  overflow: initial;
}
.mcf-overflow--overlay {
  overflow: overlay;
}
.mcf-overflow--scroll {
  overflow: scroll;
  overscroll-behavior: contain;
}
.mcf-overflow--unset {
  overflow: unset;
}
.mcf-overflow--visible {
  overflow: visible;
}

.mcf-overflow-x--auto {
  overflow-x: auto;
}
.mcf-overflow-x--hidden {
  overflow-x: hidden;
}
.mcf-overflow-x--inherit {
  overflow-x: inherit;
}
.mcf-overflow-x--initial {
  overflow-x: initial;
}
.mcf-overflow-x--overlay {
  overflow-x: overlay;
}
.mcf-overflow-x--scroll {
  overflow-x: scroll;
  overflow-y: visible;
  overscroll-behavior-x: contain;
  scroll-behavior: smooth;
}
.mcf-overflow-x--unset {
  overflow-x: unset;
}
.mcf-overflow-x--visible {
  overflow-x: visible;
}

.mcf-overflow-y--auto {
  overflow-y: auto;
}
.mcf-overflow-y--hidden {
  overflow-y: hidden;
}
.mcf-overflow-y--inherit {
  overflow-y: inherit;
}
.mcf-overflow-y--initial {
  overflow-y: initial;
}
.mcf-overflow-y--overlay {
  overflow-y: overlay;
}
.mcf-overflow-y--scroll {
  overflow-y: scroll;
  overscroll-behavior-y: contain;
  scroll-behavior: smooth;
}
.mcf-overflow-y--unset {
  overflow-y: unset;
}
.mcf-overflow-y--visible {
  overflow-y: visible;
}
.mcf-scrollbar--none::-webkit-scrollbar {
  display: none;
}
