// Framework grid generation
//

@mixin make-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width, $breakpoints: $grid-breakpoints) {
  // Propriétés communes pour tous les breakpoints
  %mcf-grid-column {
    position: relative;
    width: 100%;
    min-height: 1px; // Empêche les colonnes de s'effondrer lorsqu'elles sont vides

    &:not(.mcf-form-control):not(.mcf-form-control-wrapper):not(.mcf-form-switcher):not(.mcf-form-control-clearable) {
      padding-right: ($gutter / 2);
      padding-left: ($gutter / 2);
    }
  }

  @each $breakpoint in map-keys($breakpoints) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    // Autoriser les colonnes à s'étendre sur toute la largeur en dessous de leurs breakpoints
    @for $i from 1 through $columns {
      .mcf-col#{$infix}-#{$i} {
        @extend %mcf-grid-column;
      }
    }
    .mcf-col#{$infix},
    .mcf-col#{$infix}-auto {
      @extend %mcf-grid-column;
    }

    @include media-breakpoint-up($breakpoint, $breakpoints) {
      // Fournit des classes `.col-{bp}` de base pour les colonnes flexbox de largeur égale
      .mcf-col#{$infix} {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .mcf-col#{$infix}-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: none; // Réinitialiser les anciens niveaux
      }

      @for $i from 1 through $columns {
        .mcf-col#{$infix}-#{$i} {
          @include make-col($i, $columns);
        }
      }

      .mcf-order#{$infix}-first { order: -1; }

      .mcf-order#{$infix}-last { order: $columns + 1; }

      @for $i from 0 through $columns {
        .mcf-order#{$infix}-#{$i} { order: $i; }
      }

      // `$columns - 1` parce que le décalage par la largeur d'une ligne entière n'est pas possible
      @for $i from 0 through ($columns - 1) {
        @if not ($infix == "" and $i == 0) { // Évite d'émettre des informations inutiles .offset-0
          .mcf-offset#{$infix}-#{$i} {
            @include make-col-offset($i, $columns);
          }
        }
      }
    }
  }
}

@mixin flex-grid-item($arg, $margin: null) {
  flex-basis: calc(100% * 1 / #{$arg} - #{$margin});
  @if $margin != null {
    margin-right: $margin / 2;
    margin-bottom: $margin;
    margin-left: $margin / 2;
  }
}

@mixin flex-grid-row($margin) {
  display: flex;
  flex-wrap: wrap;
  margin-right: -$margin / 2;
  margin-left: -$margin / 2;
}
