.mcf-textline {
  background-color: $textbox-background-color;
  @include padding-x($textbox-left-right-padding-x);
  @include padding-y($textbox-up-down-padding-y);
  &__header,
  &__footer {
    // TODO : a faire si besoin
  }
  &__body {
    display: flex;
  }
  &__input {
    @include margin-right($textbox-margin-right);
    flex: 1 1 auto;
    border: none;
    &:focus {
      outline: none;
    }
  }
  input {
    border: none !important;
    &:focus,
    &:hover {
      border: none !important;
    }
  }
  &__btn {
    @include button-variant($gris-sable, $gris-sable);
    border-radius: $textbox-button-border-radius;
    padding: $textbox-button-padding;
    @extend .mcf-btn--sm;
    @extend .mcf-btn;
    min-width: inherit; // TODO: surcharge macif
    min-height: inherit; // TODO: surcharge macif
    height: auto; // TODO: surcharge macif
    width: auto; // TODO: surcharge macif
  }
}
