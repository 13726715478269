// stylelint-disable declaration-no-important

// Flex variation
//
// Styles personnalisés pour des options d'alignement flexibles supplémentaires.

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .mcf-flex#{$infix}--row            { flex-direction: row !important; }
    .mcf-flex#{$infix}--column         { flex-direction: column !important; }
    .mcf-flex#{$infix}--row-reverse    { flex-direction: row-reverse !important; }
    .mcf-flex#{$infix}--column-reverse { flex-direction: column-reverse !important; }

    .mcf-flex#{$infix}--wrap         { flex-wrap: wrap !important; }
    .mcf-flex#{$infix}--nowrap       { flex-wrap: nowrap !important; }
    .mcf-flex#{$infix}--wrap-reverse { flex-wrap: wrap-reverse !important; }
    .mcf-flex#{$infix}--fill         { flex: 1 1 auto !important; }
    .mcf-flex#{$infix}--grow-0       { flex-grow: 0 !important; }
    .mcf-flex#{$infix}--grow-1       { flex-grow: 1 !important; }
    .mcf-flex#{$infix}--shrink-0     { flex-shrink: 0 !important; }
    .mcf-flex#{$infix}--shrink-1     { flex-shrink: 1 !important; }

    .mcf-justify-content#{$infix}--start   { justify-content: flex-start !important; }
    .mcf-justify-content#{$infix}--end     { justify-content: flex-end !important; }
    .mcf-justify-content#{$infix}--center  { justify-content: center !important; }
    .mcf-justify-content#{$infix}--between { justify-content: space-between !important; }
    .mcf-justify-content#{$infix}--around  { justify-content: space-around !important; }

    .mcf-align-items#{$infix}--start    { align-items: flex-start !important; }
    .mcf-align-items#{$infix}--end      { align-items: flex-end !important; }
    .mcf-align-items#{$infix}--center   { align-items: center !important; }
    .mcf-align-items#{$infix}--baseline { align-items: baseline !important; }
    .mcf-align-items#{$infix}--stretch  { align-items: stretch !important; }

    .mcf-align-content#{$infix}--start   { align-content: flex-start !important; }
    .mcf-align-content#{$infix}--end     { align-content: flex-end !important; }
    .mcf-align-content#{$infix}--center  { align-content: center !important; }
    .mcf-align-content#{$infix}--between { align-content: space-between !important; }
    .mcf-align-content#{$infix}--around  { align-content: space-around !important; }
    .mcf-align-content#{$infix}--stretch { align-content: stretch !important; }

    .mcf-align-self#{$infix}--auto     { align-self: auto !important; }
    .mcf-align-self#{$infix}--start    { align-self: flex-start !important; }
    .mcf-align-self#{$infix}--end      { align-self: flex-end !important; }
    .mcf-align-self#{$infix}--center   { align-self: center !important; }
    .mcf-align-self#{$infix}--baseline { align-self: baseline !important; }
    .mcf-align-self#{$infix}--stretch  { align-self: stretch !important; }
  }
}
