// Utilitaire d'opacité
$opacities: () !default;
$opacities: map-merge((
  0: 0,
  1: .1,
  2: .2,
  3: .3,
  4: .4,
  5: .5,
  6: .6,
  7: .7,
  8: .8,
  9: .9,
  10: 1
), $opacities);

@each $opacity, $value in $opacities {
  .mcf-opacity--#{$opacity} {
    opacity: $value !important;
  }
}
