//
// Base styles
//

.mcf-card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $card-bg;
  background-clip: border-box;
  border: $card-border-width solid $card-border-color;
  @include border-radius($card-border-radius);

  > hr {
    margin-right: 0;
    margin-left: 0;
  }

  > .mcf-card__list-group:first-child {
    .mcf-card__list-group-item:first-child {
      @include border-top-radius($card-border-radius);
    }
  }

  > .mcf-card__list-group:last-child {
    .mcf-card__list-group-item:last-child {
      @include border-bottom-radius($card-border-radius);
    }
  }

  &--shadow {
    @include box-shadow($card-box-shadow);
  }

  &--dashed {
    border-style: dashed !important;
    border-width: $border-width-thick !important;
  }

  &--link {
    cursor: pointer;
    &:hover {
      @include box-shadow($card-hover-box-shadow);
    }
  }
}

.mcf-card__body {
  // Activer `flex-grow: 1` pour les decks et les groupes afin que les blocs de cartes occupent
  // autant d'espace que possible, en veillant à ce que les footers soient alignés vers le bas.
  flex: 1 1 auto;
  word-break: break-word;
  @include padding($card-spacer-y, $card-spacer-x);
}

.mcf-card__title {
  @include margin-bottom($card-spacer-y - 1);
}

.mcf-card__subtitle {
  @include margin-top(-($card-spacer-y - 2));
  margin-bottom: 0;
}

.mcf-card__text:last-child {
  margin-bottom: 0;
}

.mcf-card__link {
  @include hover {
    text-decoration: none;
  }

  + .mcf-card__link {
    @include margin-left($card-spacer-x);
  }
}

//
// Optional textual caps
//

.mcf-card__header {
  @include padding($card-spacer-y, $card-spacer-x);
  margin-bottom: 0; // Supprime la marge par défaut de <hN>
  background-color: $card-cap-bg;
  border-bottom: $card-border-width solid $card-border-color;

  &:first-child {
    @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
  }

  + .mcf-card__list-group {
    .mcf-card__list-group-item:first-child {
      border-top: 0;
    }
  }
}

.mcf-card__footer {
  @include padding($card-spacer-y, $card-spacer-x);
  background-color: $card-cap-bg;
  border-top: $card-border-width solid $card-border-color;

  &:last-child {
    @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
  }
}


//
// Header navs
//

.mcf-card__header-tabs {
  @include margin-x(-($card-spacer-x - 1));
  @include margin-bottom(-$card-spacer-x);
  border-bottom: 0;
}

.mcf-card__header-pills {
  @include margin-x(-($card-spacer-x - 1));
}

// Card image
.mcf-card__img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: $card-img-overlay-padding;
}

.mcf-card__img {
  width: 100%; // Obligatoire parce que nous utilisons flexbox et ceci applique intrinsèquement align-self: stretch
  @include border-radius($card-inner-border-radius);
}

// Card image caps
.mcf-card__img--top {
  width: 100%; // Obligatoire parce que nous utilisons flexbox et ceci applique intrinsèquement align-self: stretch
  @include border-top-radius($card-inner-border-radius);
}

.mcf-card__img--bottom {
  width: 100%; // Obligatoire parce que nous utilisons flexbox et ceci applique intrinsèquement align-self: stretch
  @include border-bottom-radius($card-inner-border-radius);
}


// Card deck

.mcf-card-deck {
  display: flex;
  flex-direction: column;

  .mcf-card {
    @include margin-bottom($card-deck-margin);
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    @include margin-x(-$card-deck-margin);

    .mcf-card {
      display: flex;
      flex: 1 0 0%;
      flex-direction: column;
      @include margin-x($card-deck-margin);
      margin-bottom: 0; // Remplacer la valeur par défaut
    }
  }
}


//
// Card groups
//

.mcf-card-group {
  display: flex;
  flex-direction: column;

  > .mcf-card {
    @include margin-bottom($card-group-margin);
  }

  @include media-breakpoint-up(sm) {
    flex-flow: row wrap;
    > .mcf-card {
      flex: 1 0 0%;
      margin-bottom: 0;

      + .mcf-card {
        margin-left: 0;
        border-left: 0;
      }

      // Manipuler les coins arrondis
      @if $enable-rounded {
        &:first-child {
          @include border-right-radius(0);

          .mcf-card__img--top,
          .mcf-card__header {
            border-top-right-radius: 0;
          }
          .mcf-card__img--bottom,
          .mcf-card__footer {
            border-bottom-right-radius: 0;
          }
        }

        &:last-child {
          @include border-left-radius(0);

          .mcf-card__img--top,
          .mcf-card__header {
            border-top-left-radius: 0;
          }
          .mcf-card__img--bottom,
          .mcf-card__footer {
            border-bottom-left-radius: 0;
          }
        }

        &:only-child {
          @include border-radius($card-border-radius);

          .mcf-card__img--top,
          .mcf-card__header {
            @include border-top-radius($card-border-radius);
          }
          .mcf-card__img--bottom,
          .mcf-card__footer {
            @include border-bottom-radius($card-border-radius);
          }
        }

        &:not(:first-child):not(:last-child):not(:only-child) {
          @include border-radius(0);

          .mcf-card__img--top,
          .mcf-card__img--bottom,
          .mcf-card__header,
          .mcf-card__footer {
            @include border-radius(0);
          }
        }
      }
    }
  }
}


//
// Columns
//

.mcf-card-columns {
  .mcf-card {
    @include margin-bottom($card-columns-margin);
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
    orphans: 1;
    widows: 1;

    .mcf-card {
      display: inline-block;
      width: 100%;
    }
  }
}


//
// Accordion
//

.mcf-accordion {
  .mcf-card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0;
  }

  .mcf-card:not(:first-of-type) {
    .mcf-card__header:first-child {
      border-radius: 0;
    }
  }

  .mcf-card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  .mcf-card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
