//
// Base styles
//

.mcf-alert {
  position: relative;
  @include padding($alert-padding-y, $alert-padding-x);
  @include margin-bottom($alert-margin-bottom);

  &--shadow {
    @include box-shadow($card-box-shadow);
  }

  // Headings pour les grosses alertes
  &__heading {
    // Spécifié pour éviter les conflits de changement $headings-color
    color: inherit;
  }

  // Fournit une classe pour les liens qui correspondent aux alertes
  &__link {
    font-weight: $alert-link-font-weight;
  }

  // Dismissible alerts
  //
  // Étendre le padding de droite et tenir compte du positionnement du bouton close.
  &--dismissible {
    // Ajuste la position du bouton close
    .close {
      position: absolute;
      top: 0;
      right: 0;
      @include padding($alert-padding-y, $alert-padding-x);
      color: inherit;
    }
  }

  // Alternate styles
  //
  // Générer des classes de modificateurs contextuels pour coloriser l'alerte.

  @each $color, $value in $theme-colors {
    &--#{$color} {
      @include alert-variant($value);
    }
  }
}
