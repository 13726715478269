/// Grid system
//
// Générer des colonnes de grille sémantique avec ces mixins.

@mixin make-container($padding: $grid-container-padding) {
  width: 100%;

  @include media-breakpoint-up("sm") {
    padding-left: $padding;
    padding-right: $padding;
  }

  @include media-breakpoint-down("sm") {
    padding-left: 0;
    padding-right: 0;
  }
  margin-right: auto;
  margin-left: auto;
}

// Pour chaque breakpoint, définir la largeur maximale du conteneur dans un media query
@mixin make-container-max-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
  @each $breakpoint, $container-max-width in $max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

@mixin make-row($gutter: $grid-gutter-width) {
  display: flex;
  flex-wrap: wrap;
}

@mixin make-col-ready($gutter: $grid-gutter-width) {
  position: relative;
  // Empêchez les colonnes de devenir trop étroites lorsque vous utilisez
  // des niveaux de grille plus petits en définissant toujours `width: 100%;`
  // Cela fonctionne car nous utilisons plus tard des valeurs `flex` pour remplacer cette largeur initiale.
  width: 100%;
  padding-right: ($gutter / 2);
  padding-left: ($gutter / 2);
}

@mixin make-col($size, $columns: $grid-columns) {
  flex: 0 0 percentage($size / $columns);
  // Ajoutez un `max-width` pour vous assurer que le contenu de chaque
  // colonne ne supprime pas la largeur de la colonne.
  // S'applique à IE10 + et Firefox. Chrome et Safari ne semblent pas l'exiger.
  max-width: percentage($size / $columns);
}

@mixin make-col-offset($size, $columns: $grid-columns) {
  $num: $size / $columns;
  margin-left: if($num == 0, 0, percentage($num));
}
