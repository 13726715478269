// Breakpoint viewport tailles et media queries.
//
// Les breakpoints sont définis comme une map (name: largeur minimale), du plus petit au plus grand :
//
//    (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)
//
// La map est définie dans la variable globale $grid-breakpoints et utilise comme argument $breakpoints par défaut.

// Nom du breakpoint suivant, ou null pour le dernier point d'arrêt.
//
//    >> breakpoint-next(sm)
//    md
//    >> breakpoint-next(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    md
//    >> breakpoint-next(sm, $breakpoint-names: (xs sm md lg xl))
//    md
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
  $n: index($breakpoint-names, $name);
  @return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

// Largeur minimum du breakpoint. Null pour le plus petit (premier) breakpoint.
//
//    >> breakpoint-min(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    576px
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min != 0, $min, null);
}

// Largeur maximum breakpoint. Null pour le plus grand (dernier) breakpoint.
// La valeur maximale est calculée comme le minimum du suivant moins 0,02px
// pour contourner les limitations des préfixes `min-` et `max-` et des fenêtres avec des largeurs fractionnaires.
// cf. https://www.w3.org/TR/mediaqueries-4/#mq-min-max
// Utilise 0.02px plutôt que 0.01px pour contourner un bug d'arrondi dans Safari.
// cf. https://bugs.webkit.org/show_bug.cgi?id=178261
//
//    >> breakpoint-max(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    767.98px
@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
  $next: breakpoint-next($name, $breakpoints);
  @return if($next, breakpoint-min($next, $breakpoints) - .02px, null);
}

// Renvoie une chaîne vide si le plus petit breakpoint, sinon renvoie le nom avec un tiret infront.
// Utile pour créer des utilitaires responsive.
//
//    >> breakpoint-infix(xs, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    ""  (Returns une chaîne vide)
//    >> breakpoint-infix(sm, (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px))
//    "-sm"
@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) == null, "", "-#{$name}");
}

// Media ayant au moins la largeur minimale du breakpoint. Pas de requête pour le plus petit breakpoint.
// Rend le @content appliquer au breakpoint et wider.
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media ayant au moins la largeur maximale du breakpoint. Pas de requête pour le plus grand breakpoint.
// Rend le @content appliquer au breakpoint et narrower.
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

// Media qui s'étend sur plusieurs largeurs de breakpoints.
// Rend le @content appliquer entre le breakpoints min et max
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

// Media entre les largeurs minimales et maximales du breakpoint.
// Pas de minimum pour le plus petit point de rupture, et pas de maximum pour le plus grand.
// Rend le @content s'appliquent uniquement au point de coupure donné, et non aux fenêtres plus larges ou plus étroites.
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  $max: breakpoint-max($name, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($name, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($name, $breakpoints) {
      @content;
    }
  }
}
