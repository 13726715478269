// stylelint-disable declaration-no-important

//
// Utilitaires pour des valeurs `display` communes
//

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .mcf-d#{$infix}--none         { display: none !important; }
    .mcf-d#{$infix}--inline       { display: inline !important; }
    .mcf-d#{$infix}--inline-block { display: inline-block !important; }
    .mcf-d#{$infix}--block        { display: block !important; }
    .mcf-d#{$infix}--table        { display: table !important; }
    .mcf-d#{$infix}--table-row    { display: table-row !important; }
    .mcf-d#{$infix}--table-cell   { display: table-cell !important; }
    .mcf-d#{$infix}--flex         { display: flex !important; }
    .mcf-d#{$infix}--inline-flex  { display: inline-flex !important; }
  }
}


//
// Utilitaires pour basculer `display` dans l'impression
//

@media print {
  .mcf-d-print--none         { display: none !important; }
  .mcf-d-print--inline       { display: inline !important; }
  .mcf-d-print--inline-block { display: inline-block !important; }
  .mcf-d-print--block        { display: block !important; }
  .mcf-d-print--table        { display: table !important; }
  .mcf-d-print--table-row    { display: table-row !important; }
  .mcf-d-print--table-cell   { display: table-cell !important; }
  .mcf-d-print--flex         { display: flex !important; }
  .mcf-d-print--inline-flex  { display: inline-flex !important; }
}
