// Form grid
//
// Remplacement spécial pour la `.mcf-row` de notre système de grille pour des dispositions plus strictes.

.mcf-form,
.mcf-form > fieldset {
  > .mcf-form-row,
  > .mcf-form-group,
  > .mcf-form-text,
  > .mcf-alert {
    margin-bottom: $form-group-margin-bottom;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include headings() {
    margin-top: $form-heading-margin-top;
    margin-bottom: $form-heading-margin-bottom;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@if $enable-grid-classes {
  .mcf-form-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: -$form-group-in-row-margin-top;
    margin-right: -$form-grid-gutter-width;
    margin-left: -$form-grid-gutter-width;

    > .mcf-form-group {
      margin-top: $form-group-in-row-margin-top;
    }

    > .mcf-col,
    > [class*="mcf-col-"] {
      padding-right: $form-grid-gutter-width / 2;
      padding-left: $form-grid-gutter-width / 2;
    }
  }
}

.mcf-form-group {
  label:not(.mcf-form-check__label):not(.mcf-btn--switcher):not(.mcf-form-file__label) {
    width: 100%;
    margin-bottom: 0;
    font-weight: $form-group-label-font-weight;
    line-height: $form-group-label-line-height;

    + .mcf-form-control,
    + .mcf-form-control-wrapper,
    + .mcf-input-group,
    + .mcf-form-switcher,
    + .mcf-form-select,
    + .mcf-form-file,
    + .mcf-form-check-list {
      margin-top: $label-margin-top;
    }

    .icon {
      color: $form-group-label-icon-color;
    }
  }

  .mcf-form-text + .mcf-form-control,
  .mcf-form-text + .mcf-form-control-wrapper,
  .mcf-form-text + .mcf-input-group,
  .mcf-form-text + .mcf-form-switcher,
  .mcf-form-text + .mcf-form-select,
  .mcf-form-text + .mcf-form-file,
  .mcf-form-text + .mcf-form-check-list {
    margin-top: $label-margin-top;
  }
}

// Inline forms
//
// Faites en sorte que les formulaires apparaissent inline(-block) en ajoutant la classe `.mcf-form--inline`.
// Les formulaires en ligne commencent empilés sur de très petits appareils (mobiles), puis restent en
// ligne lorsque les fenêtres atteignent <768 pixels.
//
// Requiert l'habillage des inputs et des labels avec `.mcf-form-group` pour un affichage correct des
// contrôles de formulaire HTML par défaut et de nos contrôles de formulaire personnalisés (par exemple, des inputs groups).

.mcf-form--inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; // Évite que des éléments plus courts atteignent la même hauteur que d'autres (par exemple, de petits boutons poussant à une hauteur normale)

  > .mcf-form-group {
    margin-right: $form-group-margin-right;

    &:last-child {
      margin-right: 0;
    }

    label:not(.mcf-form-check__label):not(.mcf-btn--switcher):not(.mcf-form-file__label) {
      width: auto;
      margin-bottom: 0;

      & + .mcf-form-control,
      & + .mcf-form-control-wrapper,
      & + .mcf-input-group {
        margin-top: 0;
      }
    }

    .mcf-form-text + .mcf-form-control,
    .mcf-form-text + .mcf-form-control-wrapper,
    .mcf-form-text + .mcf-input-group {
      margin-top: 0;
    }

    > * {
      margin-right: $form-group-margin-right;
      margin-bottom: 0;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  // Comme nous utilisons flex, le dimensionnement initial des cases à cocher est réduit
  // et n'occupe pas toute la largeur (ce que nous voulons pour le niveau de grille xs),
  // nous le forçons donc ici.
  .mcf-form-check {
    width: 100%;
  }

  @include media-breakpoint-up(sm) {
    label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }

    // Inline-block toutes les choses pour "inline"
    .mcf-form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0;
    }

    // Autoriser les gens à *ne pas* utiliser `.mcf-form-group`
    .mcf-form-control {
      display: inline-block;
      width: auto; // Empêcher les labels de se empiler au-dessus des inputs dans `.mcf-form-group`
      vertical-align: middle;
    }

    // Faire en sorte que les contrôles statiques se comportent comme des contrôles normaux
    .mcf-form-control--plaintext {
      display: inline-block;
      width: auto;
    }

    .mcf-input-group,
    .mcf-form-select {
      width: auto;
    }

    // Supprime la marge par défaut sur les radios / cases à cocher utilisées pour
    // l’empilement, puis annulez le flottement des radios et des cases à cocher correspondantes.
    .mcf-form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0;
    }
    .mcf-form-check__input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: $form-check-input-margin-x;
      margin-left: 0;
    }

    .mcf-custom-control {
      align-items: center;
      justify-content: center;
    }
    .mcf-custom-control__label {
      margin-bottom: 0;
    }
  }
}
