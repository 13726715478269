@mixin padding($key, $keyX: $key) {
  @include padding-y($key);
  @include padding-x($keyX);
}

@mixin padding-top($key) {
  @each $bp, $spacer in $spacers {
    @include media-breakpoint-up($bp) {
      @each $size, $length in $spacer {
        @if $key == $size {
          padding-top: $length;
        } @else if $key == -$size {
          padding-top: -$length;
        }
      }
    }
  }
}

@mixin padding-bottom($key) {
  @each $bp, $spacer in $spacers {
    @include media-breakpoint-up($bp) {
      @each $size, $length in $spacer {
        @if $key == $size {
          padding-bottom: $length;
        } @else if $key == -$size {
          padding-bottom: -$length;
        }
      }
    }
  }
}

@mixin padding-left($key) {
  @each $bp, $spacer in $spacers {
    @include media-breakpoint-up($bp) {
      @each $size, $length in $spacer {
        @if $key == $size {
          padding-left: $length;
        } @else if $key == -$size {
          padding-left: -$length;
        }
      }
    }
  }
}

@mixin padding-right($key) {
  @each $bp, $spacer in $spacers {
    @include media-breakpoint-up($bp) {
      @each $size, $length in $spacer {
        @if $key == $size {
          padding-right: $length;
        } @else if $key == -$size {
          padding-right: -$length;
        }
      }
    }
  }
}

@mixin padding-x($key) {
  @include padding-left($key);
  @include padding-right($key);
}

@mixin padding-y($key) {
  @include padding-top($key);
  @include padding-bottom($key);
}

@mixin margin($key, $keyX: $key) {
  @include margin-y($key);
  @include margin-x($keyX);
}

@mixin margin-top($key) {
  @each $bp, $spacer in $spacers {
    @include media-breakpoint-up($bp) {
      @each $size, $length in $spacer {
        @if $key == $size {
          margin-top: $length;
        } @else if $key == -$size {
          margin-top: -$length;
        }
      }
    }
  }
}

@mixin margin-bottom($key) {
  @each $bp, $spacer in $spacers {
    @include media-breakpoint-up($bp) {
      @each $size, $length in $spacer {
        @if $key == $size {
          margin-bottom: $length;
        } @else if $key == -$size {
          margin-bottom: -$length;
        }
      }
    }
  }
}

@mixin margin-left($key) {
  @each $bp, $spacer in $spacers {
    @include media-breakpoint-up($bp) {
      @each $size, $length in $spacer {
        @if $key == $size {
          margin-left: $length;
        } @else if $key == -$size {
          margin-left: -$length;
        }
      }
    }
  }
}

@mixin margin-right($key) {
  @each $bp, $spacer in $spacers {
    @include media-breakpoint-up($bp) {
      @each $size, $length in $spacer {
        @if $key == $size {
          margin-right: $length;
        } @else if $key == -$size {
          margin-right: -$length;
        }
      }
    }
  }
}

@mixin margin-x($key) {
  @include margin-left($key);
  @include margin-right($key);
}

@mixin margin-y($key) {
  @include margin-top($key);
  @include margin-bottom($key);
}
