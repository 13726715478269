.mcf-menu-connexion{
  .mcf-dropdown__menu {
    min-width: 31.25rem;
    max-width: 37.5rem;
    padding-bottom: 0;

    .mcf-dropdown__item {
      min-height: 6.25rem;
      white-space: normal;

      @include hover-focus {
        color: theme-color("primary");
      }

      &.active,
      &:active {
        @include gradient-bg(darken($dropdown-link-hover-bg, 10%));
      }
    }

    .mcf-dropdown__item--text {
      img {
        max-width: 9.375rem;
        max-height: 9.375rem;
      }
    }
  }
}
