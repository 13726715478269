// stylelint-disable declaration-no-important
// Margin and Padding
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    @each $bp, $spacer in $spacers {
      @include media-breakpoint-up($bp) {
        @each $prop, $abbrev in (margin: m, padding: p) {
          @each $size, $length in $spacer {
            .mcf-#{$abbrev}#{$infix}--#{$size} {
              #{$prop}: $length !important;
            }

            .mcf-#{$abbrev}t#{$infix}--#{$size},
            .mcf-#{$abbrev}y#{$infix}--#{$size} {
              #{$prop}-top: $length !important;
            }

            .mcf-#{$abbrev}r#{$infix}--#{$size},
            .mcf-#{$abbrev}x#{$infix}--#{$size} {
              #{$prop}-right: $length !important;
            }

            .mcf-#{$abbrev}b#{$infix}--#{$size},
            .mcf-#{$abbrev}y#{$infix}--#{$size} {
              #{$prop}-bottom: $length !important;
            }

            .mcf-#{$abbrev}l#{$infix}--#{$size},
            .mcf-#{$abbrev}x#{$infix}--#{$size} {
              #{$prop}-left: $length !important;
            }
          }
        }
      }
    }
    // Some special margin utils
    .mcf-m#{$infix}--auto {
      margin: auto !important;
    }

    .mcf-mt#{$infix}--auto,
    .mcf-my#{$infix}--auto {
      margin-top: auto !important;
    }

    .mcf-mr#{$infix}--auto,
    .mcf-mx#{$infix}--auto {
      margin-right: auto !important;
    }

    .mcf-mb#{$infix}--auto,
    .mcf-my#{$infix}--auto {
      margin-bottom: auto !important;
    }

    .mcf-ml#{$infix}--auto,
    .mcf-mx#{$infix}--auto {
      margin-left: auto !important;
    }
  }
}
