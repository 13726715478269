// stylelint-disable declaration-no-important

//
// Border
//

.mcf-border          { border: $border-width solid $border-color !important; }
.mcf-border--top     { border-top: $border-width solid $border-color !important; }
.mcf-border--right   { border-right: $border-width solid $border-color !important; }
.mcf-border--bottom  { border-bottom: $border-width solid $border-color !important; }
.mcf-border--left    { border-left: $border-width solid $border-color !important; }

.mcf-border--0        { border: 0 !important; }
.mcf-border--top--0    { border-top: 0 !important; }
.mcf-border--right--0  { border-right: 0 !important; }
.mcf-border--bottom--0 { border-bottom: 0 !important; }
.mcf-border--left--0   { border-left: 0 !important; }

@each $color, $value in $theme-colors {
  .mcf-border--#{$color} {
    border-color: $value !important;
  }
  .mcf-border--hover--#{$color}:hover{
    border-color: $value !important;
  }
}

@each $color, $value in $colors {
  .mcf-border--#{$color} {
    border-color: $value !important;
  }
  .mcf-border--hover--#{$color}:hover{
    border-color: $value !important;
  }
}

//
// Border-radius
//

.mcf-rounded {
  border-radius: $border-radius !important;
}
.mcf-rounded--top {
  border-top-left-radius: $border-radius !important;
  border-top-right-radius: $border-radius !important;
}
.mcf-rounded--right {
  border-top-right-radius: $border-radius !important;
  border-bottom-right-radius: $border-radius !important;
}
.mcf-rounded--bottom {
  border-bottom-right-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}
.mcf-rounded--left {
  border-top-left-radius: $border-radius !important;
  border-bottom-left-radius: $border-radius !important;
}

@each $border in $borders {
  .mcf-rounded--#{$border} {
    border-#{$border}-radius: $border-radius !important;
  }
}

.mcf-rounded--circle {
  border-radius: 50% !important;
}

.mcf-rounded--0 {
  border-radius: 0 !important;
}
