// Notes sur les classes:
//
// 1. Le .carousel__item--left et .carousel__item--right est utilisé pour indiquer où
// la slide active est en-tête.
// 2. .active.carousel__item est la slide courante.
// 3. .active.carousel__item--left et .active.carousel__item--right est la slide actuelle
// dans son état de transition. Un seul d'entre eux se produit à la fois.
// 4. .carousel__item-next.carousel__item--left et .carousel__item-prev.carousel__item--right
// est la slide à venir en transition.

.mcf-carousel {
  position: relative;
}

.mcf-carousel__inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.mcf-carousel__item {
  position: relative;
  display: none;
  align-items: center;
  width: 100%;
  @include transition($carousel-transition);
  backface-visibility: hidden;
  perspective: 1000px;
}

.mcf-carousel__item.active,
.mcf-carousel__item-next,
.mcf-carousel__item-prev {
  display: block;
}

.mcf-carousel__item-next,
.mcf-carousel__item-prev {
  position: absolute;
  top: 0;
}

.mcf-carousel__item-next.mcf-carousel__item--left,
.mcf-carousel__item-prev.mcf-carousel__item--right {
  transform: translateX(0);

  @supports (transform-style: preserve-3d) {
    transform: translate3d(0, 0, 0);
  }
}

.mcf-carousel__item-next,
.active.mcf-carousel__item--right {
  transform: translateX(100%);

  @supports (transform-style: preserve-3d) {
    transform: translate3d(100%, 0, 0);
  }
}

.mcf-carousel__item-prev,
.active.mcf-carousel__item--left {
  transform: translateX(-100%);

  @supports (transform-style: preserve-3d) {
    transform: translate3d(-100%, 0, 0);
  }
}


//
// Alterner les transitions
//

.mcf-carousel--fade {
  .mcf-carousel__item {
    opacity: 0;
    transition-duration: .6s;
    transition-property: opacity;
  }

  .mcf-carousel__item.active,
  .mcf-carousel__item-next.mcf-carousel__item--left,
  .mcf-carousel__item-prev.mcf-carousel__item--right {
    opacity: 1;
  }

  .active.mcf-carousel__item--left,
  .active.mcf-carousel__item--right {
    opacity: 0;
  }

  .mcf-carousel__item-next,
  .mcf-carousel__item-prev,
  .mcf-carousel__item.active,
  .active.mcf-carousel__item--left,
  .active.mcf-carousel__item--prev {
    transform: translateX(0);

    @supports (transform-style: preserve-3d) {
      transform: translate3d(0, 0, 0);
    }
  }
}


//
// Left/right controls pour la navigation
//

.mcf-carousel__control-prev,
.mcf-carousel__control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  // Utilisez flex pour l'alignement (1-3)
  display: flex; // 1. autoriser les styles flex
  align-items: center; // 2. centre verticalement le contenu
  justify-content: center; // 3. centre horizontal contenu
  width: $carousel-control-width;
  color: $carousel-control-color;
  text-align: center;
  opacity: $carousel-control-opacity;
  // Nous ne pouvons pas avoir de transition ici parce que WebKit annule
  // l'animation du carrousel si vous êtes au milieu d'une autre animation.

  // Hover/focus state
  @include hover-focus {
    color: $carousel-control-color;
    text-decoration: none;
    outline: 0;
    opacity: .9;
  }
}
.mcf-carousel__control-prev {
  left: 0;
}
.mcf-carousel__control-next {
  right: 0;
}

// Icons interne
.mcf-carousel__control-prev__icon,
.mcf-carousel__control-next__icon {
  display: flex;
  width: $carousel-control-icon-width * 2;
  height: $carousel-control-icon-width * 2;
  background: $gris-bg;
  border: solid 1px $gris-contour;
  border-radius: 50%;
}

.mcf-carousel__control-prev__icon::before,
.mcf-carousel__control-next__icon::before {
  display: flex;
  width: $carousel-control-icon-width;
  height: $carousel-control-icon-width;
  margin: auto;
  content: "";
}

.mcf-carousel__control-prev__icon::before {
  background-image: $carousel-control-prev-icon-bg;
}

.mcf-carousel__control-next__icon::before {
  background-image: $carousel-control-next-icon-bg;
}


// Optional indicator pips
//
// Ajoutez une liste ordonnée avec la classe suivante et
// ajoutez un élément de liste pour chaque diapositive que contient votre carrousel.

.mcf-carousel__indicators {
  position: absolute;
  right: 0;
  bottom: 1.5em;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  // Utilisez la largeur du .mcf-carousel__control comme marge pour ne pas se superposer
  margin-right: $carousel-control-width;
  margin-left: $carousel-control-width;
  list-style: none;

  li {
    position: relative;
    flex: 0 1 auto;
    width: $carousel-indicator-width;
    height: $carousel-indicator-height;
    margin-right: $carousel-indicator-spacer;
    margin-left: $carousel-indicator-spacer;
    @include border-radius(50%);
    text-indent: -999px;
    background-color: $carousel-indicator-bg;

    // Utilisez des pseudo-classes pour augmenter la zone de frappe de 10px en haut et en bas.
    &::before {
      position: absolute;
      top: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }
    &::after {
      position: absolute;
      bottom: -10px;
      left: 0;
      display: inline-block;
      width: 100%;
      height: 10px;
      content: "";
    }
  }

  .active {
    background-color: $carousel-indicator-active-bg;
  }
}


// Optional captions
//
//

.mcf-carousel__caption {
  position: absolute;
  right: ((100% - $carousel-caption-width) / 2);
  bottom: 20px;
  left: ((100% - $carousel-caption-width) / 2);
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: $carousel-caption-color;
  text-align: center;
}
