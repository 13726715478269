// stylelint-disable selector-no-qualifying-type

/* scss/Components/_Buttons.scss */
.mcf-btn {
  display: inline-block;
  font-weight: $btn-font-weight;
  text-align: center;
  white-space: normal;
  word-break: normal;
  vertical-align: middle;
  user-select: none;
  border: $btn-border-width solid transparent;

  @include button-size($btn-padding-y, $btn-padding-x, $font-size-base, $btn-line-height, $btn-border-radius);
  @include transition($btn-transition);

  @include hover-focus {
    text-decoration: none;
  }

  &:focus,
  &.focus {
    outline: 0;
    box-shadow: $btn-focus-box-shadow;
  }

  &.disabled,
  &:disabled {
    opacity: $btn-disabled-opacity;
    @include box-shadow(none);
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-image: none;
    @include box-shadow($btn-box-shadow);

    &:focus {
      @include box-shadow($btn-box-shadow);
    }
  }

  .icon::before {
    vertical-align: middle;
  }
}

// Désactivation des clics sur les éléments `<a>`
a.mcf-btn.disabled,
fieldset:disabled a.mcf-btn {
  pointer-events: none;
}

//
// Alternate buttons
//

@each $color, $value in $theme-colors {
  .mcf-btn--#{$color} {
    @include button-variant($value, $value);
  }
}

@if $disable-amp {
  @each $color, $value in $theme-colors {
    .mcf-btn--outline--#{$color} {
      @include button-outline-variant($value);
    }
  }
}

//
// Link buttons
//
.mcf-btn--link {
  font-weight: $font-weight-normal;
  color: $link-color;
  background-color: transparent;

  @include hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
    background-color: transparent;
    border-color: transparent;
  }

  &:focus,
  &.focus {
    text-decoration: $link-hover-decoration;
    border-color: transparent;
    box-shadow: none;
  }

  &:disabled,
  &.disabled {
    color: $btn-link-disabled-color;
    pointer-events: none;
  }

  &:not(:disabled):not(.disabled):active,
  &:not(:disabled):not(.disabled).active {
    background-image: none;
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
  }
}

.mcf-btn--icon,
.mcf-btn--icon.mcf-btn--lg,
.mcf-btn--icon.mcf-btn--sm {
  padding: $btn-padding-icon;
  border-radius: $btn-border-radius-icon;
}

//
// Button Sizes
//

.mcf-btn--lg {
  @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.mcf-btn--sm {
  @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}

// Rounded buttons
.mcf-btn--rounded{
  border-radius: $btn-rounded-radius;

  @each $color, $value in $theme-colors {
    &.mcf-btn--#{$color} {
      border-color: darken($value, 10%);
    }
  }
}

// Thin buttons
.mcf-btn--thin {
  min-height: auto;
  padding: $btn-padding-y-thin $btn-padding-x-thin;
}

// No Border
.mcf-btn--noborder{
  @each $color, $value in $theme-colors {
    &.mcf-btn--#{$color} {
      border-color: $value;
    }
  }
}

//
// Block button
//

.mcf-btn--block {
  display: block;
  width: 100%;

  // Espacer verticalement plusieurs boutons de bloc
  + .mcf-btn--block {
    margin-top: $btn-block-spacing-y;
  }
}

input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.mcf-btn--block {
    width: 100%;
  }
}
