html, body{
  height: 100vh;
}

.mcf-template--tunnel {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;

  .mcf-template__header {
    box-shadow: $tunnel-box_shadow;
  }

  .mcf-template__main {
    display: flex;
    flex: auto;
    @include media-breakpoint-down("xs") {
      flex-direction: column;
    }
    @include media-breakpoint-up("xs") {
      flex-direction: row;
    }

    .mcf-template__aside {
      min-width: 25%;
    }

    .mcf-template__content {
      width: 100%;
      align-items: center;
      margin: auto;
      overflow-x: hidden;
      @include media-breakpoint-down("xs") {
        margin: 0 auto auto auto;
        flex-grow: 1;
      }
    }
  }

  .mcf-template__footer {
    width: 100%;
  }
}
