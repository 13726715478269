// stylelint-disable selector-no-qualifying-type

// Make the div behave like a button
.mcf-form-switcher,
.mcf-form-switcher--vertical {
  position: relative;
  padding-top: $custom-control-switcher-indicator-size / 2;
  margin-right: -1em;
  margin-left: -1em;

  > .mcf-btn {
    position: relative;

    // Bring the hover, focused, and "active" buttons to the front to overlay
    // the borders properly
    &:hover,
    &:focus,
    &:active,
    &.active {
      z-index: 1;
    }
  }
}

.mcf-form-switcher--2 {
  @include flex-grid-row(1em);
  margin-bottom: -1em;

  @include media-breakpoint-up("xs") {
    > .mcf-btn {
      @include flex-grid-item(2, 1.01em);
    }
  }

  @include media-breakpoint-down("xs") {
    > .mcf-btn {
      @include flex-grid-item(1, 1.01em);
    }
  }

  &[class*="mcf-col-"] {
    width: inherit;
  }
}

.mcf-form-switcher--3 {
  @include flex-grid-row(1em);
  margin-bottom: -1em;

  @include media-breakpoint-up("sm") {
    > .mcf-btn {
      @include flex-grid-item(3, 1.01em);
    }
  }

  @include media-breakpoint-between("sm", "sm") {
    > .mcf-btn {
      @include flex-grid-item(2, 1.01em);
    }
  }

  @include media-breakpoint-down("sm") {
    > .mcf-btn {
      @include flex-grid-item(1, 1.01em);
    }
  }
}

.mcf-form-switcher--6 {
  @include flex-grid-row(1em);
  margin-bottom: -1em;

  @include media-breakpoint-up("sm") {
    > .mcf-btn {
      @include flex-grid-item(6, 1.01em);
    }
  }

  @include media-breakpoint-between("sm", "sm") {
    > .mcf-btn {
      @include flex-grid-item(4, 1.01em);
    }
  }

  @include media-breakpoint-down("sm") {
    > .mcf-btn {
      @include flex-grid-item(2, 1.01em);
    }
  }
}

// Sizing
//
// Remix the default button sizing classes into new ones for easier manipulation.

.mcf-form-switcher--sm > .mcf-btn { @extend .mcf-btn--sm; }
.mcf-form-switcher--lg > .mcf-btn { @extend .mcf-btn--lg; }

//
// Vertical button groups
//

.mcf-form-switcher--vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  > .mcf-btn {
    width: 100%;
  }

  > .mcf-btn:not(:first-child) {
    margin-top: -$btn-border-width;
  }

  // Reset rounded corners
  > .mcf-btn:not(:last-child) {
    margin-bottom: 11px;
  }
}


// Checkbox and radio options
//
// In order to support the browser's form validation feedback, powered by the
// `required` attribute, we have to "hide" the inputs via `clip`. We cannot use
// `display: none;` or `visibility: hidden;` as that also hides the popover.
// Simply visually hiding the inputs via `opacity` would leave them clickable in
// certain cases which is prevented by using `clip` and `pointer-events`.
// This way, we ensure a DOM element is visible to position the popover from.
//
// See https://github.com/twbs/bootstrap/pull/12794 and
// https://github.com/twbs/bootstrap/pull/14559 for more information.

.mcf-form-switcher-toggle {
  > .mcf-btn {
    input[type="radio"],
    input[type="checkbox"] {
      position: absolute;
      clip: rect(0, 0, 0, 0);
      pointer-events: none;
    }
  }
}

.mcf-btn--switcher {
  padding: $form-switcher-padding;
  color: color-yiq($custom-switcher-bg);
  background-color: $custom-switcher-bg;
  border-color: $custom-switcher-border-color;

  @include hover () {
    color: color-yiq($custom-switcher-hover-bg);
    background-color: $custom-switcher-hover-bg;
    border-color: $custom-switcher-hover-border-color;
  }

  &.focus,
  &:focus {
    border-color: $custom-switcher-hover-border-color;
  }

  // Disabled vient en premier si actif peut correctement le relooker
  &.disabled,
  &:disabled {
    color: color-yiq($input-disabled-bg);
    background-color: $input-disabled-bg;
    border-color: $custom-switcher-border-color;
  }

  &.active {
    color: color-yiq($custom-switcher-checked-bg);
    background-color: $custom-switcher-checked-bg;
    @if $enable-gradients {
      background-image: none; // Supprimer le dégradé pour l'état pressed/active
    }
    border-color: $custom-switcher-checked-border-color;

    @include hover () {
      color: color-yiq($custom-switcher-checked-hover-bg);
      background-color: $custom-switcher-checked-hover-bg;
      border-color: $custom-switcher-checked-hover-border-color;
    }

    &::before{
      position: absolute;
      top: -($custom-control-switcher-indicator-size / 2);
      right: -($custom-control-switcher-indicator-size / 2);
      display: block;
      min-width: $custom-control-switcher-indicator-size;
      max-width: $custom-control-switcher-indicator-size;
      min-height: $custom-control-switcher-indicator-size;
      max-height: $custom-control-switcher-indicator-size;
      margin-left: auto;
      cursor: pointer;
      content: "";
      background-color: $vert-menthe;
      background-image: $form-check-input-checked-bg-image;
      background-repeat: no-repeat;
      background-position: 1.6px 1.6px;
      background-size: $custom-control-switcher-indicator-icon-size;
      border: solid 2px $white;
      border-radius: 50%;
    }
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .icon {
    font-size: 2em;
  }
}
