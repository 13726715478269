.mcf-sticker {
  width: $sticker-size;
  height: $sticker-size;
  @include media-breakpoint-up(md){
    font-size: $sticker-font-size-md;
    @include margin($sticker-margin-md);
  }
  font-size: $sticker-font-size;
  @include margin($sticker-margin);

  .mcf-sticker__body {
    @include box-shadow($sticker-shadow);
    @include border-radius(50%);
    @include padding($sticker-padding);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    opacity: $sticker-opacity;
    .icon{
      font-size: $sticker-icon-font-size;
      @include media-breakpoint-up(md){
        font-size: $sticker-icon-font-size-md;
      }
      line-height: $sticker-line-height;
    }
    span{
      width: 100%;
      font-size: $sticker-icon-font-size / 2;
      @include media-breakpoint-up(md){
        font-size: $sticker-icon-font-size-md / 2;
      }
      line-height: $sticker-line-height;
    }
  }

  @each $size, $value in $sticker-sizes {
    &--#{$size} {
      @include media-breakpoint-up(md){
        font-size: $value + 0.125rem;
      }
      font-size: $value;
    }
  }
}
