//
// Screenreaders
//

.mcf-sr-only {
  @include sr-only();
}

.mcf-sr-only--focusable {
  @include sr-only-focusable();
}
