// Le dropdown wrapper (`<div>`)
.mcf-dropup,
.mcf-dropright,
.mcf-dropdown,
.mcf-dropleft {
  position: relative;
}

.mcf-dropdown__toggle {
  // Générer le curseur automatiquement
  @include caret ();
  &--no-caret {
    &::after,
    &::before  {
      display: none;
    }
  }
}

// Le dropdown menu
.mcf-dropdown__menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: $zindex-dropdown;
  display: none; // none par défaut, mais bloc sur "open" du menu
  float: left;
  min-width: $dropdown-min-width;
  padding: $dropdown-padding-y 0;
  margin: $dropdown-spacer 0 0;
  font-size: $font-size-base; // Redéclarer parce que l'imbrication peut entraîner des problèmes d'héritage
  color: $body-color;
  text-align: left; // Assure un alignement correct si le parent l'a modifié (par exemple, le footer d'une modal)
  list-style: none;
  background-color: $dropdown-bg;
  background-clip: padding-box;
  border: $dropdown-border-width solid $dropdown-border-color;
  @include border-radius($dropdown-border-radius);
  @include box-shadow($dropdown-box-shadow);
}

.mcf-dropdown__menu--right {
  right: 0;
  left: auto;
}

// Autorise les dropdowns à remonter (aka, dropup-menu)
// Ajoutez simplement .dropup après la classe standard .dropdown.
.mcf-dropup {
  .mcf-dropdown__menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: $dropdown-spacer;
  }

  .mcf-dropdown__toggle {
    @include caret(up);
  }
}

.mcf-dropright {
  .mcf-dropdown__menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: $dropdown-spacer;
  }

  .mcf-dropdown__toggle {
    @include caret(right);
    &::after {
      vertical-align: 0;
    }
  }
}

.mcf-dropleft {
  .mcf-dropdown__menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: $dropdown-spacer;
  }

  .mcf-dropdown__toggle {
    @include caret(left);
    &::before {
      vertical-align: 0;
    }
  }
}

// Lorsque Popper.js est activée, il réinitialise la position déroulante de base
// stylelint-disable no-duplicate-selectors
.mcf-dropdown__menu {
  &[x-placement^="top"],
  &[x-placement^="right"],
  &[x-placement^="bottom"],
  &[x-placement^="left"] {
    right: auto;
    bottom: auto;
  }
}
// stylelint-enable no-duplicate-selectors

// Dividers (essentiellement un `<hr>`) dans le dropdown
.mcf-dropdown__divider {
  @include nav-divider($dropdown-divider-bg);
}

// Liens, boutons, et plus dans le dropdown menu
//
// `<button>` précise les styles spécifiques pour le rendu des boutons
.mcf-dropdown__item {
  display: block;
  width: 100%; // Pour les `<button>`
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  clear: both;
  font-weight: $font-weight-normal;
  color: $dropdown-link-color;
  text-align: inherit; // Pour les `<button>`
  background-color: transparent; // Pour les `<button>`
  border: 0; // Pour les `<button>`

  @include hover-focus () {
    color: $dropdown-link-hover-color;
    text-decoration: none;
    @include gradient-bg($dropdown-link-hover-bg);
  }

  &.active,
  &:active {
    color: $dropdown-link-active-color;
    text-decoration: none;
    @include gradient-bg($dropdown-link-active-bg);
  }

  &.disabled,
  &:disabled {
    color: $dropdown-link-disabled-color;
    background-color: transparent;
    // Supprimer les dégradés CSS s'ils sont activés
    @if $enable-gradients {
      background-image: none;
    }
  }
}

.mcf-dropdown__menu.show {
  display: block;
}

// Dropdown header
.mcf-dropdown__header {
  display: block;
  padding: $dropdown-padding-y $dropdown-item-padding-x;
  margin-bottom: 0; // à utiliser avec des éléments d'en-tête
  font-size: $font-size-sm;
  color: $dropdown-header-color;
  white-space: nowrap; // comme avec > li > a
}

// Dropdown text
.mcf-dropdown__item--text {
  display: block;
  padding: $dropdown-item-padding-y $dropdown-item-padding-x;
  color: $dropdown-link-color;
}
