// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.mcf-input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap; // For form validation feedback
  align-items: stretch;
  width: 100%;

  > .mcf-form-control,
  > .mcf-form-control-wrapper > .mcf-form-control,
  > .mcf-form-select,
  > .mcf-form-file {
    position: relative; // For focus state's z-index
    flex: 1 1 0%;
    margin-bottom: 0;

    + .mcf-form-control,
    + .mcf-form-control-wrapper,
    + .mcf-form-select,
    + .mcf-form-file {
      margin-left: -$input-border-width;
    }
  }

  // Bring the "active" form control to the top of surrounding elements
  > .mcf-form-control:focus,
  > .mcf-form-control-wrapper > .mcf-form-control:focus,
  > .mcf-form-select:focus,
  > .mcf-form-file .mcf-form-file__input:focus ~ .mcf-form-file__label {
    z-index: 3;
  }

  // Bring the custom file input above the label
  > .mcf-form-file .mcf-form-file__input:focus {
    z-index: 4;
  }

  > .mcf-form-control,
  > .mcf-form-control-wrapper > .mcf-form-control,
  > .mcf-form-select {
    &:not(:last-child) { @include border-right-radius(0); }
    &:not(:first-child) { @include border-left-radius(0); }
  }

  > .mcf-form-control {
    &.smooth-border-right {
      @include border-right-radius(0.375rem, true);
    }

    &.smooth-border-left {
      @include border-left-radius(0.375rem, true);
    }
  }

  // Custom file inputs have more complex markup, thus requiring different
  // border-radius overrides.
  > .mcf-form-file {
    display: flex;
    align-items: center;

    &:not(:last-child) .mcf-form-file__label { @include border-right-radius(0); }
    &:not(:first-child) .mcf-form-file__label { @include border-left-radius(0); }
  }
}


// Prepend and append
//
// While it requires one extra layer of HTML for each, dedicated prepend and
// append elements allow us to 1) be less clever, 2) simplify our selectors, and
// 3) support HTML5 form validation.

.mcf-input-group--prepend,
.mcf-input-group--append {
  display: flex;

  // Ensure buttons are always above inputs for more visually pleasing borders.
  // This isn't needed for `.input-group-text` since it shares the same border-color
  // as our inputs.
  .mcf-btn {
    position: relative;
    z-index: 2;

    &:focus {
      z-index: 3;
    }
  }

  .mcf-btn + .mcf-btn,
  .mcf-btn + .mcf-input-group__text,
  .mcf-input-group__text + .mcf-input-group__text,
  .mcf-input-group__text + .mcf-btn {
    margin-left: -$input-border-width;
  }
}

.mcf-input-group--prepend { margin-right: -$input-border-width; }
.mcf-input-group--append { margin-left: -$input-border-width; }


// Textual addons
//
// Serves as a catch-all element for any text or radio/checkbox input you wish
// to prepend or append to an input.

.mcf-input-group__text {
  display: flex;
  align-items: center;
  padding: $input-padding-y $input-padding-x;
  margin-bottom: 0; // Allow use of <label> elements by overriding our default margin-bottom
  @include font-size($input-font-size); // Match inputs
  font-weight: $font-weight-normal;
  line-height: $input-line-height;
  color: $input-group-addon-color;
  text-align: center;
  white-space: nowrap;
  background-color: $input-group-addon-bg;
  border: $input-border-width solid $input-group-addon-border-color;
  @include border-radius($input-border-radius);

  // Nuke default margins from checkboxes and radios to vertically center within.
  input[type="radio"],
  input[type="checkbox"] {
    margin-top: 0;
  }
}


// Sizing
//
// Remix the default form control sizing classes into new ones for easier
// manipulation.

.mcf-input-group--lg > .mcf-form-control:not(textarea),
.mcf-input-group--lg > .mcf-form-control-wrapper > .mcf-form-control:not(textarea),
.mcf-input-group--lg > .mcf-form-select {
  height: $input-height-lg;
}

.mcf-input-group--lg > .mcf-form-control,
.mcf-input-group--lg > .mcf-form-control-wrapper > .mcf-form-control,
.mcf-input-group--lg > .mcf-form-select,
.mcf-input-group--lg > .mcf-input-group--prepend > .mcf-input-group__text,
.mcf-input-group--lg > .mcf-input-group--append > .mcf-input-group__text,
.mcf-input-group--lg > .mcf-input-group--prepend > .mcf-btn,
.mcf-input-group--lg > .mcf-input-group--append > .mcf-btn {
  padding: $input-padding-y-lg $input-padding-x-lg;
  @include font-size($input-font-size-lg);
  line-height: $input-line-height-lg;
  @include border-radius($input-border-radius-lg);
}

.mcf-input-group--sm > .mcf-form-control:not(textarea),
.mcf-input-group--sm > .mcf-form-control-wrapper > .mcf-form-control:not(textarea),
.mcf-input-group--sm > .mcf-form-select {
  height: $input-height-sm;
}

.mcf-input-group--sm > .mcf-form-control,
.mcf-input-group--sm > .mcf-form-control-wrapper > .mcf-form-control,
.mcf-input-group--sm > .mcf-form-select,
.mcf-input-group--sm > .mcf-input-group--prepend > .mcf-input-group__text,
.mcf-input-group--sm > .mcf-input-group--append > .mcf-input-group__text,
.mcf-input-group--sm > .mcf-input-group--prepend > .mcf-btn,
.mcf-input-group--sm > .mcf-input-group--append > .mcf-btn {
  padding: $input-padding-y-sm $input-padding-x-sm;
  @include font-size($input-font-size-sm);
  line-height: $input-line-height-sm;
  @include border-radius($input-border-radius-sm);
}

.mcf-input-group--lg > .mcf-form-select,
.mcf-input-group--sm > .mcf-form-select {
  padding-right: $form-select-padding-x + $form-select-indicator-padding;
}


// Prepend and append rounded corners
//
// These rulesets must come after the sizing ones to properly override sm and lg
// border-radius values when extending. They're more specific than we'd like
// with the `.input-group >` part, but without it, we cannot override the sizing.


.mcf-input-group > .mcf-input-group--prepend > .mcf-btn,
.mcf-input-group > .mcf-input-group--prepend > .mcf-input-group__text,
.mcf-input-group > .mcf-input-group--append:not(:last-child) > .mcf-btn,
.mcf-input-group > .mcf-input-group--append:not(:last-child) > .mcf-input-group__text,
.mcf-input-group > .mcf-input-group--append:last-child > .mcf-btn:not(:last-child):not(.mcf-dropdown-toggle),
.mcf-input-group > .mcf-input-group--append:last-child > .mcf-input-group__text:not(:last-child) {
  @include border-right-radius(0);
}

.mcf-input-group > .mcf-input-group--append > .mcf-btn,
.mcf-input-group > .mcf-input-group--append > .mcf-input-group__text,
.mcf-input-group > .mcf-input-group--prepend:not(:first-child) > .mcf-btn,
.mcf-input-group > .mcf-input-group--prepend:not(:first-child) > .mcf-input-group__text,
.mcf-input-group > .mcf-input-group--prepend:first-child > .mcf-btn:not(:first-child),
.mcf-input-group > .mcf-input-group--prepend:first-child > .mcf-input-group__text:not(:first-child) {
  @include border-left-radius(0);
}
