.mcf-tunnel__footer-content {
    width: 60%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    margin-left: auto;
    margin-right: auto;
    padding: $tunnel-padding;
}

.mcf-template__footer-sm{
    @include media-breakpoint-down("sm") {
      padding-bottom: $tunnel-padding-footer;
    }
  }
