// stylelint-disable declaration-no-important

//
// Text
//

//.mcf-text-monospace { font-family: $font-family-monospace; }

// Alignment

.mcf-text--justify  { text-align: justify !important; }
.mcf-text--nowrap   { white-space: nowrap !important; }
.mcf-text--truncate { @include text-truncate; }
.mcf-text--nocut    { @include text-hyphens; }

// Responsive alignment

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    .mcf-text#{$infix}--left   { text-align: left !important; }
    .mcf-text#{$infix}--right  { text-align: right !important; }
    .mcf-text#{$infix}--center { text-align: center !important; }
  }
}

// Transformation

.mcf-text--lowercase  { text-transform: lowercase !important; }
.mcf-text--uppercase  { text-transform: uppercase !important; }
.mcf-text--capitalize { text-transform: capitalize !important; }

// Weight et italic

.mcf-font-weight--light  { font-weight: $font-weight-light !important; }
.mcf-font-weight--normal { font-weight: $font-weight-normal !important; }
.mcf-font-weight--bold   { font-weight: $font-weight-bold !important; }
.mcf-font--italic        { font-family: $font-family-italic !important; }
.mcf-font--black         { font-family: $font-family-black !important; }
.mcf-font--bold          { font-family: $font-family-bold !important; }
.mcf-font--regular       { font-family: $font-family-regular !important; }
.mcf-font--base          { font-family: $font-family-base !important; }

// Text décoration

.mcf-text--underline     { text-decoration: underline !important; }

// Contextual colors

@each $color, $value in $theme-colors {
  @include text-emphasis-variant(".mcf-text--#{$color}", $value);
  .mcf-text--hover--#{$color}:hover{
    color: $value !important;
  }
}

@each $color, $value in $colors {
  @include text-emphasis-variant(".mcf-text--#{$color}", $value);
  .mcf-text--hover--#{$color}:hover{
    color: $value !important;
  }
}

.mcf-text--body { color: $body-color !important; }
.mcf-text--muted { color: $text-muted !important; }

.mcf-text--black-50 { color: rgba($black, .5) !important; }
.mcf-text--white-50 { color: rgba($white, .5) !important; }

// Misc

.mcf-text--hide {
  @include text-hide();
}

// FIXME: A remove !
.mcf-text--small-1 {
  font-size: $font-size-base * 0.875 !important;
}

.mcf-text--small-2 {
  font-size: $font-size-base * 0.75 !important;
}

.mcf-text--small-3 {
  font-size: $font-size-base * 0.625 !important;
}

.mcf-text--big-1 {
  font-size: $font-size-base * 1.125 !important;
}

.mcf-text--big-2 {
  font-size: $font-size-base * 1.25 !important;
}

.mcf-text--big-3 {
  font-size: $font-size-base * 1.375 !important;
}
// FIXME: A remove !

.mcf-text--lg {
  font-size: $font-size-lg !important;
}

.mcf-text--sm {
  font-size: $font-size-sm !important;
}

.mcf-text--xs {
  font-size: $font-size-xs !important;
}
