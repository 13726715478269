.mcf-lightbox {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 0;

  &__container {
    position: relative;

    > .mcf-lightbox__item {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  iframe {
    width: 100%;
    height: 100%;
  }

  &__nav-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    width: 100%;
    height: 100%;

    //
    // Left/right controls pour la navigation
    //

    .mcf-lightbox__control-prev,
    .mcf-lightbox__control-next {
      position: absolute;
      top: 0;
      bottom: 0;
      // Utilisez flex pour l'alignement (1-3)
      display: flex; // 1. autoriser les styles flex
      align-items: center; // 2. centre verticalement le contenu
      justify-content: center; // 3. centre horizontal contenu
      width: $lightbox-control-width;
      color: $lightbox-control-color;
      text-align: center;
      opacity: $lightbox-control-opacity;
      // Nous ne pouvons pas avoir de transition ici parce que WebKit annule
      // l'animation du carrousel si vous êtes au milieu d'une autre animation.

      // Hover/focus state
      @include hover {
        color: $lightbox-control-color;
        text-decoration: none;
        outline: 0;
        opacity: .9;
      }
    }
    .mcf-lightbox__control-prev {
      left: 0;
    }
    .mcf-lightbox__control-next {
      right: 0;
    }

    // Icons interne
    .mcf-lightbox__control-prev__icon,
    .mcf-lightbox__control-next__icon {
      display: flex;
      width: $lightbox-control-icon-width * 2;
      height: $lightbox-control-icon-width * 2;
      background: $gris-bg;
      border: solid 1px $gris-contour;
      border-radius: 50%;
    }

    .mcf-lightbox__control-prev__icon::before,
    .mcf-lightbox__control-next__icon::before {
      display: flex;
      width: $lightbox-control-icon-width;
      height: $lightbox-control-icon-width;
      margin: auto;
      content: "";
    }

    .mcf-lightbox__control-prev__icon::before {
      background-image: $lightbox-control-prev-icon-bg;
    }

    .mcf-lightbox__control-next__icon::before {
      background-image: $lightbox-control-next-icon-bg;
    }
  }

  a:hover {
    text-decoration: none;
    opacity: 1;
  }

  .mcf-modal__dialog {
    display: none;
  }

  .mcf-modal__footer {
    text-align: left;
  }

  &--loader {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    > div {
      position: relative;
      width: 40px;
      height: 40px;
      text-align: center;

      > div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        border-radius: 50%;
        opacity: .6;
        animation: sk-bounce 2s infinite ease-in-out;

        &:last-child {
          animation-delay: -1s;
        }
      }
    }
  }

  .mcf-modal__dialog &--loader {
    > div > div {
      background-color: #333;
    }
  }

  @keyframes sk-bounce {
    0%,
    100% {
      transform: scale(0);
    }

    50% {
      transform: scale(1);
    }
  }
}

.mcf-lightbox--voir-plus {
  & > div {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - #{$grid-gutter-width});
    height: 100%;
    margin-right: $grid-gutter-width/2;
    margin-left: $grid-gutter-width/2;
    color: $white;
    background-color: rgba(0, 0, 0, .5);
  }
}
