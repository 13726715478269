@mixin loader-variant($value, $color) {

  animation: 3s rotateLoader infinite cubic-bezier(.4, 0, .2, 1), 1.5s background_#{$color}_loader infinite linear;

  .mcf-loader__ball {
    animation: 1.5s background_#{$color}_ball infinite linear;
  }

  @keyframes background_#{$color}_ball {
    0% {
      background-color: $value;
    }
    50% {
      background-color: lighten($value, 10%);
    }
    100% {
      background-color: $value;
    }

  }

  @keyframes background_#{$color}_loader {
    0% {
      border-color: $value;
      border-top-color: transparent;
    }
    50% {
      border-color: lighten($value, 10%);
      border-top-color: transparent;
    }
    100% {
      border-color: $value;
      border-top-color: transparent;
    }
  }

}
