.mcf-textbox {
  position: relative;
  height: auto;

  &__textarea {
    width: 100%;
    height: auto;
    min-height: $textbox-rounded-min-height;
    padding: $textbox-rounded-padding;
    margin: 0;
    overflow: hidden;
    line-height: $textbox-rounded-line-height;
    vertical-align: middle;
    resize: none;
    border-radius: $textbox-rounded-border-radius;

    &:focus,
    &:hover {
      border-color: lighten($textbox-rounded-color, 10%);
      outline: 0;
    }
  }

  &--charcount-top{
    margin-top: 30px;
    .mcf-textbox__textarea{
      padding: $textbox-rounded-charcount-top-padding;
    }
  }

  @include media-breakpoint-down(xs){
    &--charcount-both{
      margin-top: 30px;
      .mcf-textbox__textarea{
        padding: $textbox-rounded-charcount-top-padding;
      }
    }
  }

  &__charcount {
    &--inside{
      position: absolute;
      top: inherit;
      right: 0;
      bottom: 0;
      margin: $textbox-charcount-margin;
      line-height: $textbox-charcount-inside-line-height;
    }
    position: absolute;
    top: -23px;
    right: 5px;
    z-index: 1;
    padding: 0 5px;

    font-size: small;
    line-height: $textbox-charcount-line-height;
    color: $gris-600;

    &--max{
      color: $white;
      background-color: $danger;
      border-radius: 5px;
    }

    &--both{
      @include media-breakpoint-up(sm){
        // On reprend la classe du inside
        position: absolute;
        top: inherit;
        right: 0;
        bottom: 0;
        margin: $textbox-charcount-margin;
        line-height: $textbox-charcount-inside-line-height;
      }
    }
  }

  &__btn {
    position: absolute;
    right: 0;
    bottom: -1px;
    height: 100%;
    margin: 0;
    line-height: 1.5em;
    color: $textbox-rounded-color;
    vertical-align: middle;
    background: transparent;
    border: 0 none;

    &:focus,
    &:hover {
      color: darken($textbox-rounded-color, 10%);
      outline: 0;
    }

    &:disabled {
      box-shadow: none;
      opacity: .5;
    }

    span {
      font-size: 2em;
    }
  }

  @each $color, $value in $theme-colors {
    &--#{$color} {
      .mcf-textbox__textarea {
        &:focus,
        &:hover {
          border-color: lighten($value, 10%);
        }
      }
      .mcf-textbox__btn {
        color: $value;
        &:focus,
        &:hover {
          color: darken($value, 10%);
        }
      }
    }
  }
}
