.mcf-box {
  position: relative;
  display: block;
  padding: $box-padding;
  background-color: $box-bg-color;

  &--shadow {
    @include box-shadow($box-shadow);
  }

  &--link {
    cursor: pointer;
    &:hover {
      @include box-shadow($box-shadow);
    }
  }

  &__badge {
    @extend .mcf-badge;
    position: absolute;
    top: -$badge-padding-y;
    right: -$badge-padding-y;
  }

  &__ribbon {
    float: right;
    overflow: hidden;
    width: $ribbon-width;
    height: $ribbon-height;
    align-self: flex-end;
    position: absolute;
    top: 0;
    right: 0;

    &--text {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      background: $ribbon-strong-background;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      color: $ribbon-strong-color;
      padding-bottom: $ribbon-strong-padding-bottom;
      height: $ribbon-strong-height;
      margin-top: $ribbon-strong-margin-top;
      width: $ribbon-strong-width;
      font-size: $ribbon-strong-font-size;
    }


  }

  // color variants
  @each $color, $value in map-merge($theme-colors, $colors) {
    &--#{$color} {
      color: color-yiq($value);
      background-color: $value;
    }
  }
}
