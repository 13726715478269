// stylelint-disable selector-no-qualifying-type

.fade {
  @include transition($transition-fade);

  &:not(.show) {
    opacity: 0;
  }
}

.collapse {
  visibility: hidden;
  &:not(.show) {
    display: none;
  }
  &.show{
    display: block;
    visibility: visible;
  }
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  @include transition($transition-collapse);

  &.mcf-transition-width {
    width: 0;
    height: 100%;
    @include transition($transition-collapse-width);
  }
}
