// Contents
//
// Navbar
// Navbar brand
// Navbar nav
// Navbar text
// Navbar divider
// Responsive navbar
// Navbar position
// Navbar themes

// Navbar
//
// Provide a static navbar from which we expand to create full-width, fixed, and
// other navbar variations.

.mcf-navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap; // allow us to do the line break for collapsing content
  align-items: center;
  justify-content: space-between; // space out brand from logo
  padding: $navbar-padding-y $navbar-padding-x;

  // Because flex properties aren't inherited, we need to redeclare these first
  // few properities so that content nested within behave properly.
  > .mcf-container,
  > .mcf-container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  &--vertical {
    z-index: $zindex-fixed;
    display: flex;
    flex-direction: column;
  }

  .mcf-btn,
  .mcf-navbar__brand {
    margin-top: $navbar-brand-margin-top;
    margin-bottom: $navbar-brand-margin-bottom;
  }
}

// Navbar brand
//
// Used for brand, project, or site names.

.mcf-navbar__brand {
  display: inline-block;
  padding-top: $navbar-brand-padding-y;
  padding-bottom: $navbar-brand-padding-y;
  margin-right: $navbar-padding-x;
  font-size: $navbar-brand-font-size;
  line-height: inherit;
  white-space: nowrap;

  @include hover-focus() {
    text-decoration: none;
  }
}

// Navbar nav
//
// Custom navbar navigation (doesn't require `.mcf-nav`, but does make use of `.mcf-nav-link`).

.mcf-navbar__nav {
  display: flex;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  .mcf-nav__link {
    padding: $navbar-nav-link-padding-y $nav-link-padding-x;
  }

  .mcf-dropdown-menu {
    position: static;
    float: none;
  }
}

// Navbar text
//
//

.mcf-navbar__text {
  display: inline-block;
  padding-top: $nav-link-padding-y;
  padding-bottom: $nav-link-padding-y;
}

// Responsive navbar
//
// Custom styles for responsive collapsing and toggling of navbar contents.
// Powered by the collapse Bootstrap JavaScript plugin.

// When collapsed, prevent the toggleable navbar contents from appearing in
// the default flexbox row orienation. Requires the use of `flex-wrap: wrap`
// on the `.mcf-navbar` parent.
.mcf-navbar--collapse {
  flex-basis: 100%;
  flex-grow: 1;
  // For always expanded or extra full navbars, ensure content aligns itself
  // properly vertically. Can be easily overridden with flex utilities.
  align-items: center;
}

// Button for toggling the navbar when in its collapsed state
.mcf-navbar--toggler {
  padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
  font-size: $navbar-toggler-font-size;
  line-height: 1;
  background-color: transparent; // remove default button style
  border: $border-width solid transparent; // remove default button style
  @include border-radius($navbar-toggler-border-radius);

  @include hover-focus() {
    text-decoration: none;
  }

  // Opinionated: add "hand" cursor to non-disabled .mcf-navbar-toggler elements
  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}

// Keep as a separate element so folks can easily override it with another icon
// or image file as needed.
.mcf-navbar--toggler__icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

// Generate series of `.mcf-navbar-expand-*` responsive classes for configuring
// where your navbar collapses.
.mcf-navbar--expand {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        > .mcf-container,
        > .mcf-container-fluid {
          padding-right: 0;
          padding-left: 0;
        }
      }

      @include media-breakpoint-up($next) {
        flex-flow: row nowrap;
        justify-content: flex-start;

        .mcf-navbar__nav {
          flex-direction: row;

          .mcf-dropdown-menu {
            position: absolute;
          }

          .mcf-nav__link {
            padding-right: $navbar-nav-link-padding-x;
            padding-left: $navbar-nav-link-padding-x;
          }
        }

        // For nesting containers, have to redeclare for alignment purposes
        > .mcf-container,
        > .mcf-container-fluid {
          flex-wrap: nowrap;
        }

        .mcf-navbar--collapse {
          display: flex !important; // stylelint-disable-line declaration-no-important

          // Changes flex-bases to auto because of an IE10 bug
          flex-basis: auto;
        }

        .mcf-navbar--toggler {
          display: none;
        }
      }
    }
  }
}

// Navbar themes
//
// Styles for switching between navbars with light or dark background.

@each $color, $value in $theme-colors {
  @include navbar-variant($color, $value);
}

.mcf-navbar__nav--separator {
  .mcf-nav__item + .mcf-nav__item::before {
    display: block;
    margin: $navbar-nav-separator-margin-y $navbar-nav-separator-margin-x;
    content: "";
    border-top: $navbar-nav-separator-border-top solid;
    opacity: $navbar-nav-separator-opacity;
  }
}

.mcf-spacing-navbar {
  margin-top: 6.25rem;
}
