//
// Tableau
//

.mcf-table {
  width: 100%;
  margin-bottom: $spacer;
  background-color: $table-bg; // Réinitialiser pour imbriquer les parents avec`background-color`.

  th,
  td {
    padding: $table-cell-padding;
    vertical-align: top;
    border-top: $table-border-width solid $table-border-color;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: (2 * $table-border-width) solid $table-border-color;
  }

  tbody + tbody {
    border-top: (2 * $table-border-width) solid $table-border-color;
  }

  .mcf-table {
    background-color: $body-bg;
  }
}


//
// Table condensée avec demi-padding
//

.mcf-table--sm {
  th,
  td {
    padding: $table-cell-padding-sm;
  }
}


// Border
//
// Ajoutez ou supprimez des bordures tout autour de la table et entre toutes les colonnes.

.mcf-table--bordered {
  border: $table-border-width solid $table-border-color;

  th,
  td {
    border: $table-border-width solid $table-border-color;
  }

  thead {
    th,
    td {
      border-bottom-width: (2 * $table-border-width);
    }
  }
}

.mcf-table--borderless {
  th,
  td,
  thead th,
  tbody + tbody {
    border: 0;
  }
}

// Zebra-striping
//
// Styles de bandes de zèbres par défaut (alternance d'arrière-plans gris et transparents)

.mcf-table--striped {
  tbody tr:nth-of-type(#{$table-striped-order}) {
    background-color: $table-accent-bg;
  }
}


// Hover effect
//
// Placé ici car il doit venir après le zébrage potentiel

.mcf-table--hover {
  tbody tr {
    @include hover {
      background-color: $table-hover-bg;
    }
  }
}


// Table backgrounds
//
// Les sélecteurs exacts ci-dessous sont nécessaires pour remplacer
// `.mcf-table--striped` et empêcher l’héritage des tables imbriquées.

@each $color, $value in $theme-colors {
  @include table-row-variant($color, theme-color-level($color, -9));
}

@include table-row-variant(active, $table-active-bg);


// Dark styles
//
// Même balisage de table, mais jeu de couleurs inversé: fond sombre et texte clair.

// stylelint-disable-next-line no-duplicate-selectors
.mcf-table {
  .mcf-thead--dark {
    th {
      color: $table-dark-color;
      background-color: $table-dark-bg;
      border-color: $table-dark-border-color;
    }
  }

  .mcf-thead--light {
    th {
      color: $table-head-color;
      background-color: $table-head-bg;
      border-color: $table-border-color;
    }
  }
}

.mcf-table--dark {
  color: $table-dark-color;
  background-color: $table-dark-bg;

  th,
  td,
  thead th {
    border-color: $table-dark-border-color;
  }

  &.mcf-table--bordered {
    border: 0;
  }

  &.mcf-table--striped {
    tbody tr:nth-of-type(odd) {
      background-color: $table-dark-accent-bg;
    }
  }

  &.mcf-table--hover {
    tbody tr {
      @include hover {
        background-color: $table-dark-hover-bg;
      }
    }
  }
}


// Responsive tables
//
// Générez des séries de classes `.mcf-table--responsive-*` pour
// configurer la taille d’écran à l’endroit où votre table débordera.

.mcf-table--responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;

        // Empêcher la double bordure sur le défilement horizontal en raison de l'utilisation de `display: block;`
        > .mcf-table--bordered {
          border: 0;
        }
      }
    }
  }
}
