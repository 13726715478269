// Range
//
// Les styles de range est identique dans tous les navigateurs. Les règles spécifiques au fournisseur
// pour les pseudo-éléments ne peuvent pas être mélangées. En tant que tel, il n'y a pas de styles
// partagés pour le focus ou des états actifs sur les sélecteurs préfixés.

.form-range {
  width: 100%;
  height: calc(#{$form-range-thumb-height} + #{$form-range-thumb-focus-box-shadow-width * 2});
  padding: 0; // Need to reset padding
  background-color: transparent;
  appearance: none;

  &:focus {
    outline: none;

    // Les pseudo-éléments doivent être répartis sur plusieurs jeux de règles pour avoir un effet.
    // Pas de mixin box-shadow() pour l'accessibilité du focus.
    &::-webkit-slider-thumb { box-shadow: $form-range-thumb-focus-box-shadow; }
    &::-moz-range-thumb     { box-shadow: $form-range-thumb-focus-box-shadow; }
    &::-ms-thumb            { box-shadow: $form-range-thumb-focus-box-shadow; }
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-thumb {
    width: $form-range-thumb-width;
    height: $form-range-thumb-height;
    margin-top: ($form-range-track-height - $form-range-thumb-height) / 2; // Webkit spécifique
    @include gradient-bg($form-range-thumb-bg);
    border: $form-range-thumb-border;
    @include border-radius($form-range-thumb-border-radius);
    @include box-shadow($form-range-thumb-box-shadow);
    @include transition($custom-forms-transition);
    appearance: none;

    &:active {
      @include gradient-bg($form-range-thumb-active-bg);
    }
  }

  &::-webkit-slider-runnable-track {
    width: $form-range-track-width;
    height: $form-range-track-height;
    color: transparent; // Why?
    cursor: $form-range-track-cursor;
    background-color: $form-range-track-bg;
    border-color: transparent;
    @include border-radius($form-range-track-border-radius);
    @include box-shadow($form-range-track-box-shadow);
  }

  &::-moz-range-thumb {
    width: $form-range-thumb-width;
    height: $form-range-thumb-height;
    @include gradient-bg($form-range-thumb-bg);
    border: $form-range-thumb-border;
    @include border-radius($form-range-thumb-border-radius);
    @include box-shadow($form-range-thumb-box-shadow);
    @include transition($custom-forms-transition);
    appearance: none;

    &:active {
      @include gradient-bg($form-range-thumb-active-bg);
    }
  }

  &::-moz-range-track {
    width: $form-range-track-width;
    height: $form-range-track-height;
    color: transparent;
    cursor: $form-range-track-cursor;
    background-color: $form-range-track-bg;
    border-color: transparent; // Firefox spécifique?
    @include border-radius($form-range-track-border-radius);
    @include box-shadow($form-range-track-box-shadow);
  }

  &::-ms-thumb {
    width: $form-range-thumb-width;
    height: $form-range-thumb-height;
    margin-top: 0; // Edge spécifique
    margin-right: $form-range-thumb-focus-box-shadow-width; // La solution de contournement qui fait déborder le box-shadow est masquée.
    margin-left: $form-range-thumb-focus-box-shadow-width;  // La solution de contournement qui fait déborder le box-shadow est masquée.
    @include gradient-bg($form-range-thumb-bg);
    border: $form-range-thumb-border;
    @include border-radius($form-range-thumb-border-radius);
    @include box-shadow($form-range-thumb-box-shadow);
    @include transition($custom-forms-transition);
    appearance: none;

    &:active {
      @include gradient-bg($form-range-thumb-active-bg);
    }
  }

  &::-ms-track {
    width: $form-range-track-width;
    height: $form-range-track-height;
    color: transparent;
    cursor: $form-range-track-cursor;
    background-color: transparent;
    border-color: transparent;
    border-width: $form-range-thumb-height / 2;
    @include box-shadow($form-range-track-box-shadow);
  }

  &::-ms-fill-lower {
    background-color: $form-range-track-bg;
    @include border-radius($form-range-track-border-radius);
  }

  &::-ms-fill-upper {
    margin-right: 15px; // arbitraire?
    background-color: $form-range-track-bg;
    @include border-radius($form-range-track-border-radius);
  }

  &:disabled {
    &::-webkit-slider-thumb {
      background-color: $form-range-thumb-disabled-bg;
    }

    &::-webkit-slider-runnable-track {
      cursor: default;
    }

    &::-moz-range-thumb {
      background-color: $form-range-thumb-disabled-bg;
    }

    &::-moz-range-track {
      cursor: default;
    }

    &::-ms-thumb {
      background-color: $form-range-thumb-disabled-bg;
    }
  }
}
