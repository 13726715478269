@mixin navbar-variant($color, $value) {
  .mcf-navbar--#{$color} {
    background-color: $value;
    .mcf-navbar__brand {
      color: rgba(color-yiq($value), $navbar-color-alpha);

      @include hover-focus {
        color: rgba(color-yiq($value), $navbar-active-color-alpha);
      }
    }

    .mcf-navbar--toggler {
      color: rgba(color-yiq($value), $navbar-color-alpha);
      border-color: rgba(color-yiq($value), $navbar-toggler-border-color-alpha);
    }

    .mcf-navbar--toggler__icon {
      @if (color-yiq($value) == $yiq-text-dark) {
        background-image: $navbar-dark-toggler-icon-bg;
      } @else {
        background-image: $navbar-light-toggler-icon-bg;
      }
    }

    .mcf-navbar__text {
      color: rgba(color-yiq($value), $navbar-color-alpha);
      a {
        color: rgba(color-yiq($value), $navbar-active-color-alpha);

        @include hover-focus {
          color: rgba(color-yiq($value), $navbar-active-color-alpha);
        }
      }
    }
  }

  .mcf-navbar__nav--#{$color} {
    color: rgba(color-yiq($value), $navbar-color-alpha);
    background-color: $value;

    .mcf-nav__link {
      color: rgba(color-yiq($value), $navbar-color-alpha);

      @include hover {
        color: rgba(color-yiq($value), $navbar-hover-color-alpha);
        background-color: darken($value, 10%);
      }

      &.disabled {
        color: rgba(color-yiq($value), $navbar-disabled-color-alpha);
        background-color: transparent;
      }
    }

    .show > .mcf-nav__link,
    .active > .mcf-nav__link,
    .mcf-nav__link.show,
    .mcf-nav__link.active {
      color: rgba(color-yiq($value), $navbar-active-color-alpha);
      background-color: darken($value, 20%);
    }
  }
}
