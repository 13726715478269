.mcf-languette {
  position: relative;
  color: $languette-font-color;

  & > div {
    @include padding-bottom(3);
  }

  &.selected > div:last-child {
    @include border-bottom-radius($languette-border-radius);
  }

  &:not(.selected) > div:first-child {
    @include border-bottom-radius($languette-border-radius);
  }

  .mcf-languette__header,
  .mcf-languette__body,
  .mcf-languette__footer {
    @include padding-left(3);
    @include padding-right(3);
    display: flex;
    align-content: center;
    align-items: stretch;
    justify-content: center;
    background-color: $gris-sable;
  }


  .mcf-languette__header {
    @include padding-top(3);
    @include border-top-radius($languette-border-radius);
    position: relative;
    cursor: pointer;
  }

  .mcf-languette__title,
  .mcf-languette__subtitle,
  .mcf-languette__desc {
    display: flex;
    width: 100%;
  }

  .mcf-languette__desc {
    margin-top: 0;
    margin-bottom: 0;
  }

  .mcf-languette__title,
  .mcf-languette__subtitle {
    @include padding-bottom($languette-title-subtitle-padding-bottom);
    font-size: $languette-title-subtitle-font-size;
    font-weight: $languette-title-subtitle-font-weight;
    line-height: $languette-title-subtitle-line-height;
  }

  .mcf-languette__desc,
  .mcf-languette__text {
    width: 100%;
    font-size: $languette-desc-text-font-size;
    line-height: $languette-title-subtitle-line-height;
  }

  .mcf-languette__left,
  .mcf-languette__right {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .mcf-languette__left {
    flex-direction: row;
    @include margin-right($languette-left-right-margin-y);

    .mcf-languette__checkbox {
      @extend .mcf-custom-checkbox;
      position: relative;
      width: $languette-checkbox-label-before-after-width;
      height: $languette-checkbox-label-before-after-height;

      &:not(:last-child) {
        @include margin-right($languette-left-right-margin-y);
      }

      .mcf-custom-control__label {
        &::before {
          width: $languette-checkbox-label-before-after-width;
          height: $languette-checkbox-label-before-after-height;
        }

        &::after {
          width: $languette-checkbox-label-before-after-width;
          height: $languette-checkbox-label-before-after-height;
          background-size: 80% 80%;
        }
      }
    }

    .mcf-languette__radio {
      @extend .mcf-custom-radio;
      position: relative;
      width: $languette-checkbox-label-before-after-width;
      height: $languette-checkbox-label-before-after-height;
      &:not(:last-child) {
        @include margin-right($languette-left-right-margin-y);
      }

      .mcf-custom-control__label {
        &::before {
          width: $languette-checkbox-label-before-after-width;
          height: $languette-checkbox-label-before-after-height;
        }

        &::after {
          width: $languette-checkbox-label-before-after-width;
          height: $languette-checkbox-label-before-after-height;
        }
      }
    }
  }

  .mcf-languette__right {
    flex-direction: column;
    @include margin-left($languette-left-right-margin-y);
    @include padding-left($languette-left-right-margin-y);
    min-width: 20%;
    max-width: 20%;
    border-left: $languette-content-border;
    &.mcf-languette__right--no-border {
      border-left: none;
    }
  }

  .mcf-languette__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-content: center;
    align-items: center;
    width: 100%; // fix pour IE, car IE c'est de la merde

    &:not(:last-child) {
      @include padding-right($languette-left-right-margin-left);
    }
  }

  .icon {
    font-size: 3.125rem;
  }

  .mcf-languette__footer,
  .mcf-languette__body {
    position: relative;
    //z-index: -1;
    display: none;
    //transition: z-index 1s linear;
  }

  &.selected {
    position: relative;
    color: $white;

    .mcf-languette__header,
    .mcf-languette__body,
    .mcf-languette__footer {
      background-color: $bleu-ciel;
    }

    .mcf-languette__right {
      border-color: $white;
    }

    .mcf-languette__body,
    .mcf-languette__footer {
      display: flex;
    }
  }

  &.disabled {
    color: $gris-600;

    .mcf-languette__checkbox {
      .mcf-languette__checkbox__label {
        &::before {
          border-color: $gris-600;
        }
      }
    }
  }

  .mcf-form-row {
    width: 100%; //fix IE
  }
}
