// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//
.mcf-h1 {
  @extend h1;
}

.mcf-h2 {
  @extend h2;
}

.mcf-h3 {
  @extend h3;
}

.mcf-h4 {
  @extend h4;
}

.mcf-h5 {
  @extend h5;
}

.mcf-h6 {
  @extend h6;
}

.mcf-subtitle {
  font-family: $headings-subtitle-font-family;
  @include font-size($headings-subtitle-font-size);
  font-weight: $headings-subtitle-font-weight;
  line-height: $headings-subtitle-line-height;
  color: $headings-color;
  margin-top: 0;
  margin-bottom: $headings-margin-bottom;
  font-style: $headings-font-style;
}

.mcf-lead {
  @include font-size($lead-font-size);
  font-weight: $lead-font-weight;
}

// Type display classes
.mcf-display-1 {
  @include font-size($display1-size);
  font-weight: $display1-weight;
  line-height: $display1-line-height;
}
.mcf-display-2 {
  @include font-size($display2-size);
  font-weight: $display2-weight;
  line-height: $display2-line-height;
}
.mcf-display-3 {
  @include font-size($display3-size);
  font-weight: $display3-weight;
  line-height: $display3-line-height;
}
.mcf-display-4 {
  @include font-size($display4-size);
  font-weight: $display4-weight;
  line-height: $display4-line-height;
}

//
// Emphasis
//

.mcf-small {
  @extend small;
}

.mcf-mark {
  @extend mark;
}


//
// Lists
//

.mcf-list-unstyled {
  @include list-unstyled;
}

// Inline turns list items into inline-block
.mcf-list-inline {
  @include list-unstyled;
}
.mcf-list-inline-item {
  display: inline-block;

  &:not(:last-child) {
    margin-right: $list-inline-padding;
  }
}


//
// Misc
//

// Builds on `abbr`
.mcf-initialism {
  @include font-size(90%);
  text-transform: uppercase;
}

// Blockquotes
.mcf-blockquote {
  margin-bottom: $spacer;
  @include font-size($blockquote-font-size);
}

.mcf-blockquote-footer {
  display: block;
  @include font-size($blockquote-small-font-size);
  color: $blockquote-small-color;

  &::before {
    content: "\2014\00A0"; // em dash, nbsp
  }
}

// Icons

@each $size, $value in $sizing {
  .mcf-icon--#{$size} { @include font-size($value, true); }
}

.mcf-icon--left::before {
  margin-left: 0;
}

.mcf-icon--right::before {
  margin-right: 0;
}
