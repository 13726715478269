@mixin alert-variant($background) {
  color: color-yiq($background);
  background-color: $background;
  border-color: $background;

  hr {
    border-top-color: darken($background, 5%);
  }

  .mcf-alert__link {
    color: color-yiq($background);
  }
}
