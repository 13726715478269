@mixin reset-text {
  font-family: $font-family-base;
  font-style: normal;
  font-weight: $font-weight-normal;
  line-height: $line-height-base;
  text-align: left; // Repli au cas où `start` n'est pas supporté
  text-align: start; // stylelint-disable-line declaration-block-no-duplicate-properties
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
}
