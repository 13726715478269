@mixin tunnel-mixin-color($parent, $color) {
  #{$parent} {
    @include media-breakpoint-between("lg","xl") {
      color: color-yiq($color);
      background-color: $color !important;

      a.mcf-lien {
        color: color-link-yiq($color);
      
        &:hover {
          color: color-link-hover-yiq($color);
        }
      }
    }
  }
}

@mixin tunnel-mixin-mobile-color($parent, $color) {
  #{$parent} {
    @include media-breakpoint-between("xs","md") {
      color: color-yiq($color);
      background-color: $color !important;

      a.mcf-lien {
        color: color-link-yiq($color);
      
        &:hover {
          color: color-link-hover-yiq($color);
        }
      }
    }
  }
}