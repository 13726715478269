// Container widths
//
// Définissez la largeur du conteneur et remplacez-la pour les barres de navigation fixes dans les media queries.

@if $enable-grid-classes {
  .mcf-container {
    @include make-container();
    @include make-container-max-widths();
  }
}

// Fluid container
//
// Utilise la mixin pour les containers de largeur fixe, mais avec une largeur de 100% pour les layouts fluid et full width

@if $enable-grid-classes {
  .mcf-container-fluid {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    margin-right: auto;
    margin-left: auto;
  }
}

// Row
//
// Les lignes contiennent et effacent les floats de vos colonnes

@if $enable-grid-classes {
  .mcf-row {
    @include make-row();
  }

  // Supprimez la marge négative de la classe .row par défaut,
  // puis le padding horizontal de toutes les colonnes enfants immédiates (pour empêcher l'héritage de style inherit).
  .mcf-no-gutters {
    margin-right: 0;
    margin-left: 0;

    > .col,
    > [class*="col-"] {
      padding-right: 0;
      padding-left: 0;
    }
  }
}

// Columns
//
// Styles communs pour les petites et grandes colonnes

@if $enable-grid-classes {
  @include make-grid-columns();
}
