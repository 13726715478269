.mcf-chat {
  padding: $chat-padding;
  @include media-breakpoint-up("sm") {
    padding: $chat-padding-sm;
  }
  @include media-breakpoint-up("md") {
    padding: $chat-padding-md;
  }

  .mcf-chatbulle:not(:first-child) {
    margin-top: $chatbulle-margin-top;
  }
}

@each $color, $value in $theme-colors {
  .mcf-chat--#{$color} {
    background-color: $value;
    .mcf-chatbulle__header,
    .mcf-chatbulle__footer,
    .mcf-chatbulle__body {
      color: color-yiq($value);
    }
  }
}

@each $color, $value in $theme-colors {
  .mcf-chatbulle--#{$color} {
    .mcf-chatbulle__body {
      .mcf-chatbulle__center{
        padding: $chatbulle-body-center-padding;
        color: color-yiq($value);
        background-color: $value;
        @include border-radius($chatbulle-border-radius);
      }
    }
  }
}

.mcf-chatbulle {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-left: $chatbulle-margin-x;
  @include media-breakpoint-up("sm") {
    margin-left: $chatbulle-margin-x-sm;
  }
  @include media-breakpoint-up("md") {
    margin-left: $chatbulle-margin-x-md;
  }

  &--left {
    // max-width: max-content;
    align-items: flex-start;
    justify-content: flex-start;
    margin-right: $chatbulle-margin-x;
    @include media-breakpoint-up("sm") {
      margin-right: $chatbulle-margin-x-sm;
    }
    @include media-breakpoint-up("md") {
      margin-right: $chatbulle-margin-x-md;
    }

    margin-left: 0;

    .mcf-chatbulle__body {
      justify-content: flex-start;
    }

    @each $color, $value in $theme-colors {
      &.mcf-chatbulle--#{$color} {
        .mcf-chatbulle__body {
          .mcf-chatbulle__center{
            @include border-radius($chatbulle-border-radius-left);
          }
        }
      }
    }
  }

  &__header,
  &__footer {
    padding: $chatbulle-headerfooter-padding;
    font-size: $font-size-sm;
    color: $gris-fonce;
  }

  &__body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;

    .mcf-chatbulle__left{
      padding: $chatbulle-body-left-padding;
    }

    .mcf-chatbulle__center{
      @include media-breakpoint-down("sm") {
        overflow: hidden;
      }
      *:last-child {
        margin-bottom: 0;
      }
    }

    .mcf-chatbulle__right{
      @include media-breakpoint-down("xs") {
        display: none;
      }
      padding: $chatbulle-body-right-padding;
    }

    .mcf-chatbulle__choiceList{
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      overflow: hidden;

      @include media-breakpoint-up("md") {
        display: block;
        overflow-x: visible;
      }

      .mcf-chatbulle__item{
        display: inline-block;
        margin-right: 1em;
        margin-bottom: 1em;
        vertical-align: top;

        &:last-child{
          margin-right: 0;
        }

        @include media-breakpoint-up("md") {
          .mcf-btn{
            width: max-content;
          }
        }
      }
    }
  }
}


